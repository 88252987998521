import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import PublicationData from "./PublicationsData.json";
import "./chart.css";
import {useTheme} from "../../../ThemeProvider";

// 各年ごとのデータを計算
const yearData = PublicationData.reduce((acc, item) => {
    const year = item.pub_date.substring(0, 4);

    if (!acc[year]) {
        acc[year] = {
            year,
            reviewed: 0,
            notReviewed: 0,
            total: 0
        };
    }

    acc[year].total++;

    if (item.pub_review) {
        acc[year].reviewed++;
    } else {
        acc[year].notReviewed++;
    }

    return acc;
}, {});

// データオブジェクトの配列を作成
const chartData = Object.values(yearData);

export default function LineChartComponent() {
    const { bgColor, fontColor } = useTheme();

    return (
        <div className='chart-wrapper'>
        <div className='chart-hide-scrollbar'>
        <LineChart width={800} height={400} data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 30 }}>
            <CartesianGrid strokeDasharray="5 5"/>
            <XAxis tick={{ fill: fontColor }} label={{ value: 'Year', position: 'insideBottom', offset:-20 }} dataKey="year"/>
            <YAxis tick={{ fill: fontColor }} label={{ value: 'Publications', angle: -90, position: 'insideLeft', offset:15 }} />
            <Tooltip
                contentStyle={{ backgroundColor: bgColor, borderColor: fontColor, borderStyle: 'solid', borderWidth: 2 }}
                wrapperStyle={{
                    borderColor: fontColor, // 枠線の色
                    borderWidth: 2, // 枠線の太さ
                    borderStyle: 'solid', // 枠線のスタイル
                    backgroundColor:bgColor 
                }}
            />
            <Legend style={{ color: fontColor }} align="right" verticalAlign="top" wrapperStyle={{ position: 'absolute', top: 0, right: 0 }} />
            <Line type="monotone" dataKey="reviewed" stroke="skyblue" strokeWidth={2}strokeDasharray="5 5" name="査読あり"/>
            <Line type="monotone" dataKey="notReviewed" stroke="#ffc658" strokeWidth={2} strokeDasharray="5 5"name="査読なし"/>
            <Line type="monotone" dataKey="total" stroke="yellow" strokeWidth={4} name="合計"/>
        </LineChart>
        </div>
        </div>
    );
}