//MyResearchTheme
import {Breadcrumb} from 'react-bootstrap';
import OphioneullusImg from "./src/Ophioneullus.jpg"
import MyResearchThemeIcon from './src/MyResearchThemeIcon.jpg';

export default function MyResearchTheme() {
    return (
        <>
            <Breadcrumb style={{zIndex:"9",color:"white"}}>
                <Breadcrumb.Item href="/" style={{zIndex:"9"}}>Home</Breadcrumb.Item>
                <Breadcrumb.Item active style={{zIndex:"9",color:"white"}}>PTPとbPTPをWindows環境で動かす</Breadcrumb.Item>
            </Breadcrumb>

            <h1 className='page-type-name'>ABOUT</h1>

            <div className='about-me-title-area-container'>
                <img className='about-me-img' src={ MyResearchThemeIcon } alt="So Shimizu"/>
                <div className='about-me-title-area'>
                    <h1 className='page-subtitle'>研究テーマ</h1>
                    <h1 className='page-title'>RESERARCH THEME</h1>
                    <br/>
                    <p>
                        専門：昆虫分類学、進化生物学、ヒメバチ学<br/>
                        Discipline: insect taxonomy, evolutionary biology, ichneumonology
                    </p>
                </div>
            </div>

        <h2 className='section-title'>方針</h2>
        <p>
            私は寄生性ハチ類を主な材料に昆虫分類学と進化生物学の研究に取り組んでいます。
            私のメインの研究対象である寄生性ハチ類は、代表的な捕食寄生性昆虫です。
            寄生性ハチ類は全生物の中でもトップクラスの多様性を誇ると言われていますが、
            その実態は未だに不明です。
            私は自身の研究を通して、<span className='txt-underline'>寄生性ハチ類の生物多様性の実態の解明と、
            その成り立ちや維持の仕組みの理解を目指しています</span>。
        </p>
        <p>
            私は分類学の中でも特に系統関係を考慮した分類体系の構築を目指す
            <span className='txt-underline'>系統分類学（進化分類学、evolutionary systematicsなど）や統合分類学（integrative taxonomy）</span>に積極的に取り組んでいます。
            伝統的な形態形質のみではなく、遺伝学や進化学、生態学、統計学など様々な研究分野の技術に基づき、遺伝子配列情報や系統関係、生態的特性、化学物質特性なども種が有する形質として分類に用います。
            また、形態形質に関しても、最新の理論と技術に基づいた形態計測学や統計的手法により種の境界を探索します。
            それにより、形態形質のみでは分類が困難だった分類群の多様性の解明が進んだり、
            進化的なユニットとしての分類単位を記載出来るなどのメリットが期待されます。
            さらに、特別な訓練を必要とせずに抽出が可能な同定形質も多く提供されるので、
            非分類学者にも容易に従来の難分類群分類群の同定が可能となり、
            <span className='txt-underline'>分類上に成り立つ多くの研究のスピードのアップ</span>にも貢献出来ると確信しています。
        </p>

        <h2 className='section-title'>研究①　ヒメバチ上科寄生性ハチ類の分類学</h2>
        <p>
            ヒメバチ上科は中～大型の昆虫であるにも関わらずその分類の難易度は動物の中でもトップレベルです。
            形態形質の種内変異の大きさや種間差の小ささなどにより、伝統的な形態分類のみでは分類が困難なグループが多く含まれています。
            そのように伝統的に難分類群であると言われてきたヒメバチ上科を対象に、最新の手法を用いて分類学に取り組んでいます。
        </p>

        <h2 className='section-title-sub'>最近の研究テーマ Recent taxonomic projects</h2>
        <ul>
            <li>
                ヒメバチ上科のデータベース構築<br/>
                Construction of ichneumonoid database
            </li>
            <li>
                ヒメバチ上科の核DNAバーコード領域の探索<br/>
                Exploring nuclear DNA barcoding gene for Ichneumonoidea
            </li>
            <li>
                世界のアメバチ亜科の系統と分類<br/>
                Phylogeny and taxonomy of world Ophioninae
            </li>
            <li>
                コンボウアメバチ亜科の系統と高次体系<br/>
                Phylogeny and systematics of Anomaloninae
            </li>
            <li>
                アジアのコンボウアメバチ亜科の分類学<br/>
                Taxonomy of Asian Anomaloninae
            </li>
            <li>
                ナギナタハバチの寄生性ハチ類の分類<br/>
                Taxonomy of parasitoid wasps of Xyelid sawflies
            </li>
        </ul>
        <h2 className='section-title-sub'>代表的な研究成果</h2>
        <ul>
            <li><span className='txt-underline'>Shimizu, S.</span>, Broad, G.R., Maeto, K. (2020) Integrative taxonomy and analysis of species richness patterns of nocturnal Darwin wasps of the genus <i>Enicospilus</i> Stephens (Hymenoptera, Ichneumonidae, Ophioninae) in Japan. <i>ZooKeys</i> <b>990:</b> 1-144. https://doi.org/10.3897/zookeys.990.55542</li>
            <li><span className='txt-underline'>Shimizu, S.</span>, Alvarado, M. (2020) A new genus and two new species of the subfamily Nesomesochorinae Ashmead (Insecta: Hymenoptera: Ichneumonidae). <i>Neotropical Entomology</i> <b>49(5):</b> 704-712. https://doi.org/10.1007/s13744-020-00778-7</li>
        </ul>

        <h2 className='section-title'>研究②　寄生性ハチ類の地域ファウナ</h2>
        <p>
            人間活動などによって急速に環境の変化が生じている現在、
            環境の変化をいち早く検出し、生物多様性の保護対策を行うためににも、
            多様性の現状を把握することは非常に重要です。
            そこで私は、各地においてフィールドワークを行い、そこに生息する寄生性ハチ類相を調べています。
        </p>
        <h2 className='section-title-sub'>最近の研究テーマ Recent faunistic projects</h2>
        <ul>
            <li>
                新潟県のヒメバチ上科相<br/>
                Ichneumonoid fauna of Niigata Pref., Japan
            </li>
            <li>
                中国山地のヒメバチ上科相<br/>
                Ichneumonoid fauna of Mts. Chugoku, western portion of Honshu, Japan
            </li>
        </ul>
        <h2 className='section-title-sub'>代表的な研究成果</h2>
        <ul>
            <li><b>清水壮</b> (2014) 新潟県産ヒメバチ科目録. <i>越佐昆虫同好会誌</i> <b>(111):</b> 1-25.</li>
            <li>藤江隼平・<b>清水壮</b> (2015) 新潟県産コマユバチ科目録. <i>越佐昆虫同好会誌</i> <b>(113):</b> 9-23.</li>
        </ul>
        
        <h2 className='section-title'>研究③　寄生性ハチ類の進化</h2>
        <p>
            寄生性ハチ類の多様化の謎に迫るべく、様々な視点から寄生性ハチ類の進化の道筋の解明に挑んでいます。
        </p>
        <h2 className='section-title-sub'>最近の研究テーマ Recent faunistic projects</h2>
        <ul>
            <li>
                アメバチ亜科群におけるゲノム系統と夜行性生態の進化<br/>
                Phylogenomics and evolution of nocturnal traits in Ophioniformes Darwin wasps
            </li>
        </ul>
        </>
    );
};