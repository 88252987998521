import React, {useState} from 'react';
import { Breadcrumb, Table } from 'react-bootstrap';
import icon from './icon.png';

import "highlight.js/styles/github.css";
import hljs from "highlight.js";
import { useEffect } from "react";


export default function SangerSeqProtocol() {
  useEffect(() => {
    hljs.highlightAll();
  });

  // KOD FX NEO TABLE 1
  const [ NoKodFxNeoTable1, setNoKodFxNeoTable1] = useState(1);
  const handleNoKodFxNeoTable1Change = (e) => {
    setNoKodFxNeoTable1(e.target.value);
  };
  // KOD FX NEO TABLE 2
  const [ NoKodFxNeoTable2, setNoKodFxNeoTable2] = useState(1);
  const handleNoKodFxNeoTable2Change = (e) => {
    setNoKodFxNeoTable2(e.target.value);
  };
  // KOD FX NEO TABLE 3
  const [ NoKodFxNeoTable3, setNoKodFxNeoTable3] = useState(1);
  const handleNoKodFxNeoTable3Change = (e) => {
    setNoKodFxNeoTable3(e.target.value);
  };
  // Exo Sap-IT 1
  const [ NoExoSapIt1, setNoExoSapIt1] = useState(1);
  const handleNoExoSapIt1Change = (e) => {
    setNoExoSapIt1(e.target.value);
  };
  // Exo Sap-IT 2
  const [ NoExoSapIt2, setNoExoSapIt2] = useState(1);
  const handleNoExoSapIt2Change = (e) => {
    setNoExoSapIt2(e.target.value);
  };
  // CycleSeqTable1
  const [ CycleSeqTable1, setCycleSeqTable1] = useState(1);
  const handleCycleSeqTable1Change = (e) => {
    setCycleSeqTable1(e.target.value);
  };
  // CycleSeqTable2
  const [ CycleSeqTable2, setCycleSeqTable2] = useState(1);
  const handleCycleSeqTable2Change = (e) => {
    setCycleSeqTable2(e.target.value);
  };


  return (
    <>
      <Breadcrumb style={{zIndex:"9",color:"white"}}>
        <Breadcrumb.Item href="/" style={{zIndex:"9"}}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active style={{zIndex:"9",color:"white"}}>サンガー法の実験プロトコル：DNA抽出・PCR・電気泳動・精製・Cycle Sequencing反応</Breadcrumb.Item>
      </Breadcrumb>

      <h1 className='page-type-name'>TIPS</h1>
      <div className='about-me-title-area-container'>
        <img className='about-me-img' src={ icon } alt="So Shimizu" style={{backgroundColor:"white"}}/>
        <div className='about-me-title-area'>
          <h1 className='page-subtitle'>分子実験</h1>
          <h1 className='page-title'>サンガー法の実験プロトコル：DNA抽出・PCR・電気泳動・精製・Cycle Sequencing反応</h1>
          <br/>
          <p>
          サンガー法の実験方法に関する簡単なメモです。
          </p>
        </div>
      </div>

      <h2 className='section-title'>はじめに</h2>
      <section>
        <p>
          昆虫などのDNA解析に必要なサンガー法のプロトコルをメモします。実験環境や設備によって、プロトコルは異なるので、適宜、適切なプロトコルを採用する必要があります。
        </p>
      </section>

      <h2 className='section-title' id="#目次" style={{display:"block",paddingTop:"180px",
marginTop:"-180px"}}>目次</h2>
      <section>
        <div className='table-of-contents'>
          <ol>
            <li>
              <a href="#DNA抽出">DNA抽出</a>
              <ol>
                <li><a href="#材料の用意">材料の用意</a></li>
                <li><a href="#抽出方法の選択">抽出方法の選択</a></li>
                <li><a href="#アルカリ溶解">アルカリ溶解</a></li>
                <li><a href="#Chelex">Chelex</a></li>
                <li><a href="#Qiagen">Qiagen</a></li>
                <li><a href="#抽出液の保存">抽出液の保存</a></li>
              </ol>
            </li>
            <li>
              <a href="#KOD-FX-NEOを用いたPCR">KOD FX NEOを用いたPCR</a>
              <ol>
                <li><a href="#KOD-FX-NEOを用いたPCR-反応液の調製">反応液の調製</a></li>
                <li><a href="#KOD-FX-NEOを用いたPCR-反応条件">反応条件</a></li>
              </ol>
            </li>
            <li><a href="#電気泳動">電気泳動</a></li>
            <li>
              <a href="#PCR産物の精製">PCR産物の精製</a>
              <ol>
                <li><a href='#PCR産物の精製-Exo-Sap-ITを用いた精製'>Exo Sap-ITを用いた精製</a></li>
                <li><a href='#PCR産物の精製-GFX-Kitを用いた精製'>GFX Kitを用いた精製</a></li>
                <li><a href='#PCR産物の精製-Premixでシーケンス外注に出す場合'>Premixでシーケンス外注に出す場合</a></li>
              </ol>
            </li>
            <li>
              <a href="#Cycle-Sequencing反応">Cycle Sequencing反応</a>
              <ol>
                <li><a href="#Cycle-Sequencing反応-反応液の調製">反応液の調製</a></li>
                <li><a href="#Cycle-Sequencing反応-PCR">PCR</a></li>
                <li><a href="#Cycle-Sequencing反応-余剰蛍光物質の除去（精製）">余剰蛍光物質の除去（精製）</a></li>
              </ol>
            </li>
          </ol>
        </div>
      </section>

      <h2 className='section-title' id="#DNA抽出" style={{display:"block",paddingTop:"180px",
marginTop:"-180px"}}>DNA抽出</h2>
      <section>
        <h3 className='section-title' id='材料の用意' style={{display:"block",paddingTop:"180px",
marginTop:"-180px"}}>材料の用意</h3>
        <p>
          新鮮なサンプルや大型昆虫の場合は、フセツ程度で十分です。古い標本や小型昆虫などの場合は、より組織が多く含まれる脚足全体を使用します。さらに著しく古いサンプルや特に小型の昆虫は虫体全体を用います。
        </p>
        <h3 className='section-title' id='抽出方法の選択' style={{display:"block",paddingTop:"180px",
marginTop:"-180px"}}>抽出方法の選択</h3>
        <p>
          状態の良いサンプルの場合や抽出液の量が少なくても良い場合は、最安価かつ簡単なアルカリ抽出を用います。状態の悪いサンプルや微量のサンプルの場合は、ChelexやQiagenを用います。個人的には、手間と費用がかかりますが、複数回の実験のやり直しや虫体の損傷を最低限にするためにも、最初から成功率が高いQiagenを用いる事を推奨します。
        </p>
        <h3 className='section-title' id='アルカリ溶解' style={{display:"block",paddingTop:"180px",
marginTop:"-180px"}}>アルカリ溶解</h3>
        <ul>
          <li>メリット：最安価・簡単・短時間</li>
          <li>デメリット：抽出効率が低い→新鮮な試料向け</li>
        </ul>
        <ol>
          <li>抽出に用いるサンプルをPCRチューブに入れる。</li>
          <li>サンプルの入ったPCRチューブに50 mM NaOHを20 μL入れる。この際、サンプルがしっかりと50 mM NaOHに浸かるにする（場合によっては遠心機を用いて沈める）。</li>
          <li>サーマルサイクラーで95℃で15分インキュベートする。</li>
          <li>200 mM Tris-HCLを20 μL入れ、極性を除き、抽出完了です。</li>
        </ol>
        <h3 className='section-title' id='Chelex' style={{display:"block",paddingTop:"180px",
marginTop:"-180px"}}>Chelex</h3>
        <ul>
          <li>メリット：Qiagenより安く、アルカリ溶解より抽出効率が高い・簡単</li>
          <li>デメリット：時間がかかる</li>
        </ul>
        <ol>
          <li>抽出に用いるサンプルをPCRチューブに入れる。</li>
          <li>サンプルの入ったPCRチューブにChelex 5% (or 10%)溶液を30 μL入れ、Proteinase Kを20 μL入れる。この際、サンプルがしっかりと液に浸かるようにする。</li>
          <li>サーマルサイクラーで56℃で2時間以上インキュベートする（小型昆虫や古いサンプルは一晩から2, 3日）。</li>
          <li>99.9℃で3分インキュベートし、抽出完了です。</li>
        </ol>
        <h3 className='section-title' id='Qiagen' style={{display:"block",paddingTop:"180px",
marginTop:"-180px"}}>Qiagen</h3>
        <ul>
          <li>メリット：圧倒的に抽出効率が高い（他の方法でダメでもQiagenならいけることが多い）・最終的に得られる液量が多い</li>
          <li>デメリット：高価・時間がかかる・処理が多い</li>
        </ul>
        <ol>
          <li>
            <b>サンプルを用意</b>
            <ul>
              <li>
                エタノール液浸などの場合は、60℃または常温でインキュベートして実験開始までに乾燥させておく。乾燥させておくことで、サンプルをすり潰し易くなる。
              </li>
            </ul>
          </li>
          <li>
            <b>抽出に用いるサンプルを1.5 mLチューブに入れる</b>
            <ul>
              <li>
                証拠標本を残す必要が無い場合は<b>ペッスルを用いてサンプルをすり潰す</b>。証拠標本を残す場合はすり潰す必要はないが、可能であれば関節膜などに穴を空けておくと抽出効率があがる。
              </li>
            </ul>
          </li>
          <li>
            <b>チューブにBuffer ATLを180 μLとProteinase Kを20 μL入れる</b>
            <ul>
              <li>
                静電気などの影響でペッスルからサンプルの組織断片が取りにくいことがあるので、Buffer ATLで洗いながらペッスルをチューブから抜き取ると楽である。
              </li>
            </ul>
          </li>
          <li>
            <b>15秒間程度ボルテックスする。</b>
          </li>
          <li>
            <b>56℃で1時間以上インキュベートする</b>
            <ul>
              <li>10分、30分後などに適宜ボルテックスを行うこと。</li>
              <li>微小昆虫や古い標本からの抽出では一晩から2、3日程度インキュベートする。その際も、定期的にボルテックスを行う。</li>
              <li>インキュベート後にもボルテックスする。</li>
            </ul>
          </li>
          <li>
            <b>Buffer ALを200 μL加え、全体が均一になるまでボルテックスする</b>
          </li>
          <li>
            <b>100%エタノールを200 μL加え、白い沈殿が目立たなくなるまで入念にボルテックスする</b>
          </li>
          <li>
            <b>ボルテックス完了した液をQiagen Spin Columnに移し8,000 rpm以上で1分間遠心</b>
          </li>
          <li>
            <b>新しい2.0 mLふた無しコレクションチューブにカラムを移す</b>
            <ul>
              <li>前工程で生じたチューブ内の溶液は廃棄してよい。</li>
            </ul>
          </li>
          <li>
            <b>カラムにBuffer AW1を500 μL入れ、8,000 rpm以上で1分間遠心</b>
          </li>
          <li>
            <b>新しい2.0 mLふた無コレクションチューブにカラムを移す</b>
            <ul>
              <li>前工程で生じたチューブ内の溶液は廃棄してよい。</li>
            </ul>
          </li>
          <li>
            <b>カラムにBuffer AW2を500 μL入れ、14,000 rpmで3分間遠心</b>
          </li>
          <li>
            <b>新しい1.5 mLのフタ有チューブにカラムを移す</b>
            <ul>
              <li>尚、前工程で生じたチューブ内の溶液は廃棄してよい。</li>
            </ul>
          </li>
          <li>
            <b>カラムにBuffer AEを200 μL入れ、1分間静置</b>
          </li>
          <li>
            <b>8,000 rpmで1分間遠心し、抽出完了</b>
          </li>
        </ol>
        <p>
          ※Qiagenの試薬類は25℃のインキュベーターで保存した方が良いです。ただし、Proteinase KはQiagen付属のものは25℃インキュベーター、他社製品は冷凍庫や冷蔵庫などメーカー指定温度を確認し保存します。
          <br/>
          ※Buffer ATLは特に低温になると結晶ができやすいです。使用前に必ず状態を確認し、結晶がある場合は60℃インキュベーターで温めて結晶を溶かし、軽く攪拌してから使用します。
          <br/>
          ※AW1とAW2はEtOHを加えて使用。
        </p>
        <h3 className='section-title' id='抽出液の保存' style={{display:"block",paddingTop:"180px",
marginTop:"-180px"}}>抽出液の保存</h3>
        <ul>
          <li>-30℃で冷凍保存する。</li>
        </ul>
      </section>


      {/* PCR関連事項 */}
      <section>
      <h2 className='section-title' id='KOD-FX-NEOを用いたPCR' style={{display:"block",paddingTop:"180px",
marginTop:"-180px"}}>KOD FX NEOを用いたPCR</h2>
      <h3 className='section-title' id='KOD-FX-NEOを用いたPCR-反応液の調製' style={{display:"block",paddingTop:"180px",
marginTop:"-180px"}}>反応液の調製</h3>
        <ul>
          <li>
            <p>微小昆虫や古い標本からの抽出液を用いる場合、1サンプル当たり以下の分量で調製する。</p>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr><th>試薬          </th><th>量（μL）</th>
                  <th>
                    <select onChange={handleNoKodFxNeoTable1Change}>
                      {Array.from({ length: 200 }, (_, i) => (
                        <option key={i} value={i+1}>
                          {i+1}
                        </option>
                      ))}
                    </select>サンプル分（µL）
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr><td>2× seq. Buffer</td><td>12.5   </td><td>{(12.5*NoKodFxNeoTable1).toFixed(1)}</td></tr>
                <tr><td>dNTPs         </td><td>5.0    </td><td>{(5.0*NoKodFxNeoTable1).toFixed(1)}</td></tr>
                <tr><td>プライマー（F）*</td><td>1.0   </td><td>{(1.0*NoKodFxNeoTable1).toFixed(1)}</td></tr>
                <tr><td>プライマー（R）*</td><td>1.0   </td><td>{(1.0*NoKodFxNeoTable1).toFixed(1)}</td></tr>
                <tr><td>KOD FX NEO     </td><td>0.5   </td><td>{(0.5*NoKodFxNeoTable1).toFixed(1)}</td></tr>
                <tr><td>抽出液         </td><td>5.0    </td><td>{(5.0*NoKodFxNeoTable1).toFixed(1)}</td></tr>
                <tr><th>合計           </th><th>25.0   </th><td>{(25.0*NoKodFxNeoTable1).toFixed(1)}</td></tr>
              </tbody>
            </Table>
          </li>
          <li>
            <p>新鮮なサンプルなどからの抽出液を用いる場合は以下の分量で調製する。</p>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr><th>試薬           </th><th>量（μL）</th>
                  <th>
                    <select onChange={handleNoKodFxNeoTable2Change}>
                      {Array.from({ length: 200 }, (_, i) => (
                        <option key={i} value={i+1}>
                          {i+1}
                        </option>
                      ))}
                    </select>サンプル分（µL）
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr><td>2× seq. Buffer </td><td>12.5</td><td>{(12.5*NoKodFxNeoTable2).toFixed(1)}</td></tr>
                <tr><td>dNTPs          </td><td>5.0</td><td>{(5.0*NoKodFxNeoTable2).toFixed(1)}</td></tr>
                <tr><td>プライマー（F）*</td><td>0.5</td><td>{(0.5*NoKodFxNeoTable2).toFixed(1)}</td></tr>
                <tr><td>プライマー（R）*</td><td>0.5</td><td>{(0.5*NoKodFxNeoTable2).toFixed(1)}</td></tr>
                <tr><td>miliQ          </td><td>1.0</td><td>{(1.0*NoKodFxNeoTable2).toFixed(1)}</td></tr>
                <tr><td>KOD FX NEO     </td><td>0.5</td><td>{(0.5*NoKodFxNeoTable2).toFixed(1)}</td></tr>
                <tr><td>抽出液          </td><td>5.0</td><td>{(5.0*NoKodFxNeoTable2).toFixed(1)}</td></tr>
                <tr><th>合計            </th><th>25.0</th><td>{(25.0*NoKodFxNeoTable2).toFixed(1)}</td></tr>
              </tbody>
            </Table>
          </li>
          <li>
            <p>節約する場合は総反応液10 μLの以下の分量で調製する。</p>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr><th>試薬           </th><th>量（μL）</th>
                  <th>
                    <select onChange={handleNoKodFxNeoTable3Change}>
                      {Array.from({ length: 200 }, (_, i) => (
                        <option key={i} value={i+1}>
                          {i+1}
                        </option>
                      ))}
                    </select>サンプル分（µL）
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr><td>2× seq. Buffer </td><td>5.0</td><td>{(5.0*NoKodFxNeoTable3).toFixed(1)}</td></tr>
                <tr><td>dNTPs          </td><td>2.0</td><td>{(2.0*NoKodFxNeoTable3).toFixed(1)}</td></tr>
                <tr><td>プライマー（F）*</td><td>0.3</td><td>{(0.3*NoKodFxNeoTable3).toFixed(1)}</td></tr>
                <tr><td>プライマー（R）*</td><td>0.3</td><td>{(0.3*NoKodFxNeoTable3).toFixed(1)}</td></tr>
                <tr><td>miliQ          </td><td>1.2</td><td>{(1.2*NoKodFxNeoTable3).toFixed(1)}</td></tr>
                <tr><td>KOD FX NEO     </td><td>0.2</td><td>{(0.2*NoKodFxNeoTable3).toFixed(1)}</td></tr>
                <tr><td>抽出液          </td><td>1.0</td><td>{(1.0*NoKodFxNeoTable3).toFixed(1)}</td></tr>
                <tr><th>合計            </th><th>10.0</th><td>{(10.0*NoKodFxNeoTable3).toFixed(1)}</td></tr>
              </tbody>
            </Table>
          </li>
        </ul>
        <p>PCRに用いる抽出液の濃度が高すぎるとアニーリングがうまくいかないので適宜調整を行う。</p>
        <h3 className='section-title' id='KOD-FX-NEOを用いたPCR-反応条件' style={{display:"block",paddingTop:"180px",
marginTop:"-180px"}}>反応条件</h3>
        <p>
          使用するプライマーに合わせてサーマルサイクラーにかける。スメアになったり目的領域以外がよく伸びる場合、アニーリング温度をプライマーのTm値ギリギリか1-2℃高く設定すると、標的領域のみが伸びやすい。
        </p>
        <ul>
          <li>
            <p>
              代表的なバーコーディング領域（CO1: LCO1490 / HCO2198）と28S rDNA（For28Vesp / Rev28Vesp）のプライマーでの設定例
            </p>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr><th>温度（℃）</th><th>時間</th><th>回数</th></tr>
              </thead>
              <tbody>
                <tr><td>94</td><td>2分</td><td>1サイクル</td></tr>
                <tr><td>98（熱変性）</td><td>10秒</td><td rowspan="3">35サイクル</td></tr>
                <tr><td>48（アニーリング）</td><td>30秒</td></tr>
                <tr><td>68（伸長）</td><td>30秒</td></tr>
                <tr><td>72（最終伸長）</td><td>10分</td><td>1サイクル</td></tr>
                <tr><td>3</td><td>∞</td><td>∞</td></tr>
              </tbody>
            </Table>
          </li>
          <li>
            <p>
              ※PCR産物は-30℃で冷凍保存可能
            </p>
          </li>
          <li>
            <p>
              少量のDNAを伸ばす場合などは2段階PCRが有効であるが、経験上、2段階PCRをかける場合、1回目のサイクル数は24, 5回、2回目は10~15回にする。尚、2回目はアニーリング温度をプライマーのTm値ギリギリか1, 2℃高く設定する。
            </p>
          </li>
        </ul>
      </section>



      <section>
        <h2 className='section-title' id='電気泳動' style={{display:"block",paddingTop:"180px",
marginTop:"-180px"}}>電気泳動</h2>
        <ul>
          <li>
            <p>電気泳動により標的領域の伸長を確認する。</p>
            <ol>
              <li>電気泳動槽に適当なコーム数のゲルをセットする。</li>
              <li>電気泳動槽にTAE Bufferをゲルが完全に浸る量入れる。</li>
              <li>一番右のコームにladderを3.0 μL程度入れる。</li>
              <li>パラフィルム上にLoading Dyeを3.0 μL程度（目分量でOK、10.0 mLの下のバンドまで程度）産物の数だけ用意する。</li>
              <li>PCR産物3.0 μL程度（目分量でOK）をパラフィルム上でLoading Dyeとピペッティングにより混ぜる。</li>
              <li>混合液をコームに入れる。</li>
              <li>泳動層のフタをし、15分程度電気泳動する。</li>
              <li>バンドを紫外線装置で確認する。</li>
            </ol>
          </li>
          <li>
            <p>バンドが確認できないときの対応</p>
            <ul>
              <li><p>ゲルの状態が悪い可能性がある。異なるゲルで試したり、ゲルを作り直す。</p></li>
              <li>
                <p>
                  そもそもPCRに失敗している可能性があるので再PCRをする。
                  <br/>
                  特に試薬の調製時に十分に試薬が混ざっていない可能性、試薬の入れ忘れがある可能性、抽出液の濃度が薄い場合などの可能性が考えられる。
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>電気泳動後、バンドが濃すぎる、スメアになる、出ない場合、抽出液を5倍希釈してみるか、逆に濃縮してみる。</p>
            <ol>
              <li>3M Sodium Acetateを抽出液の1/10量、100%エタノールを抽出液の2.5倍量加えボルテックスする。</li>
              <li>-20℃で一晩インキュベートする。</li>
              <li>15,000 rpmで30分遠心する。</li>
              <li>上澄みを除去する。</li>
              <li>70%エタノールを750 μL加え、15,000 rpmで10分遠心する。</li>
              <li>上澄みを除去し、エタノールが完全に飛ぶように50–65℃で10分以上インキュベートする。</li>
              <li>Elution bufferを任意量加えボルテックスして完了。</li>
            </ol>
          </li>
          <li>
            <p>電気泳動用ゲルの作り方</p>
            <ol>
              <li>型を用意する。コームの設置が非常に重要で、型の底とコームの間に1–2 mm程度の隙間が出来るようにする。</li>
              <li>三角フラスコに1× TAEを入れる。</li>
              <li>三角フラスコにアガロースを入れてサランラップで蓋をし十分に混ぜる。</li>
              <li>電子レンジで温める。30–60秒ごとに取り出して、アガロースが均一になるように攪拌する。</li>
              <li>アガロースが完全に溶けて、液が透き通ったら、電子レンジから取り出し、エチジウムブロマイドを加えて混ぜて、型に流し込む。気泡等を除去する。</li>
              コツ1：一気に温めると、アガロースが完全に溶けたように見えても、フラスコの底付近と上層でアガロースの濃度が異なり、質の悪いゲルになるのみならずフラスコの掃除が大変になるので、めんどくさくてもこまめに拡販する。
            </ol>
          </li>
        </ul>
      </section>



      <section>
        <h2 className='section-title' id='PCR産物の精製' style={{display:"block",paddingTop:"180px",
marginTop:"-180px"}}>PCR産物の精製</h2>
        <h3 className='section-title' id='PCR産物の精製-Exo-Sap-ITを用いた精製' style={{display:"block",paddingTop:"180px",
marginTop:"-180px"}}>Exo Sap-ITを用いた精製</h3>
        <ol>
          <li>
            <b>PCR産物にExo Sap-ITを加える</b>
            <ul>
              <li>
                Exo Sap-ITはPCR産物の0.4倍量
                <p>
                  <input onChange={handleNoExoSapIt1Change} placeholder='PCR産物量（µL）をここに入力'></input>：加えるExo Sap-ITは<b>{(0.4*NoExoSapIt1).toFixed(1)}</b> µL/sample
                </p>
              </li>
              <li>希釈する場合、Exo Sap-ITは0.5 µL / 10 µL（次の、PCRはExoSAP Longで実施）
                <p>
                  <input onChange={handleNoExoSapIt2Change} placeholder='PCR産物量（µL）をここに入力'></input>：加えるExo Sap-ITは<b>{(0.5/10.0*NoExoSapIt2).toFixed(1)}</b> µL/sample
                </p>
              </li>
            </ul>
            
            
          </li>
          <li>
            <b>PCRによる精製の実行</b>
            <ul>
              <li>
                ExoSAP Normal
                <Table striped bordered hover variant="dark">
                  <thead>
                    <tr><th>温度（℃）</th><th>時間</th><th>回数</th></tr>
                  </thead>
                  <tbody>
                    <tr><td>37</td><td>15 min</td><td rowSpan={"2"}>1サイクル</td></tr>
                    <tr><td>80</td><td>15 min</td></tr>
                    <tr><td>3</td><td>∞</td><td>∞</td></tr>
                  </tbody>
                </Table>
              </li>
              <li>
                ExoSAP Long
                <Table striped bordered hover variant="dark">
                  <thead>
                    <tr><th>温度（℃）</th><th>時間</th><th>回数</th></tr>
                  </thead>
                  <tbody>
                    <tr><td>37</td><td>90 min</td><td rowSpan={"2"}>1サイクル</td></tr>
                    <tr><td>80</td><td>15 min</td></tr>
                    <tr><td>3</td><td>∞</td><td>∞</td></tr>
                  </tbody>
                </Table>
              </li>
            </ul>
          </li>
        </ol>

        <h3 className='section-title' id='PCR産物の精製-GFX-Kitを用いた精製' style={{display:"block",paddingTop:"180px", marginTop:"-180px"}}>GFX Kitを用いた精製</h3>
        <ul>
          <li>
            <ol>
              <li>PCR産物の入ったPCRチューブにCapture BufferをPCR産物の5倍量程度（110 μL程度）入れる。</li>
              <li>2.0 mLフタ有コレクションチューブにGFX Kitのカラムをセットし、1の液をカラムに入れる。</li>
              <li>16,000 xgで30秒間遠心し、チューブ内の液を捨てる。</li>
              <li>カラムにWash Bufferを500 μL入れる。尚、Wash Bufferが切れている場合は100% EtOHで行えば問題ない。</li>
              <li>新しい1.5 mLフタ有チューブを用意する。</li>
              <li>16,000 xgで30秒間遠心し、チューブ内の液を捨てる。</li>
              <li>カラムを新しい1.5 mLフタ有チューブに移し、カラムにElution Bufferを50 μL入れ、1分間静置する。</li>
              <li>16,000 xgで1分間遠心し、精製完了。</li>
            </ol>
          </li>
          <li>
            <p>
              GFX Kitカラムの洗浄方法<br/>
              本来、本キットのカラムは使い捨てだが、実際は、洗浄することで3回程度はリサイクルができる。
            </p>
            <ol>
              <li>
                ＜作成中＞<br/>
                ※当研究室の従来のプロトコルではカラム洗浄でもGFX KitのWash Bufferを使うように書かれているが、勿体ないので絶対にカラム洗浄にWash Bufferを使わないこと。
              </li>
              <li>
                尚、念のため、コンタミ回避のため異なるプライマーペアのもので使いまわした方がいい。
                <br/>
                （CO1で一回目使ったものは2回目はCO1ではなくCADに使うなど）
                <br/>
                また、ゲル霧したものは使いまわさずに捨てた方が無難と思われる。
              </li>
            </ol>
          </li>
          <li>
            <p>
              ゲル切りによるPCR産物の精製
              <br/>
              偽遺伝子やコピーの様な不要な領域もしばしば伸びる（特に核遺伝子）。その際は、ゲル切りを行わないと、綺麗な波形が得られず解析不能か読めてもシグナルがより強い偽遺伝子などの配列が得られていたりする。
              <ol>
                <li>幅広コームのゲルでしっかりと標的領域が独立したバンドとなるまで電気泳動を行う。</li>
                <li>紫外線装置下でゲルカッターを用いて標的バンドを切り抜き、1.5 mlチューブにゲル断片を入れる。</li>
                <li>Capture Bufferを110 μL程度入れて、60℃でインキュベートしゲルを溶かす。</li>
                <li>以降は通常のPCR産物の生成と同じ手順。</li>
              </ol>
            </p>
          </li>
          <li>
            <p>
              精製産物の濃度計測を行う。
              <br/>
              ※慣れると泳動後のバンドの濃さで凡その産物の濃度が予想付くようになる。
              <br/>
              ※Nano dropの値はほぼあてにならないので、飽くまでも目安程度。精度の高い実験では他の計測装置を用いること。
              <ol>
                <li>Nano drop 2000を起動する。</li>
                <li>Nucleo acidを選択する。</li>
                <li>台に2.0 mLのmiliQを乗せBlankを取る。</li>
                <li>台を拭き、計測する精製産物を2.0 mLを台に乗せ計測する。</li>
                <li>計測後、台を拭く。</li>
                <li>4, 5を繰り返す。</li>
              </ol>
            </p>
          </li>
        </ul>
        <h3 className='section-title' id='PCR産物の精製-Premixでシーケンス外注に出す場合' style={{display:"block",paddingTop:"180px", marginTop:"-180px"}}>Premixでシーケンス外注に出す場合</h3>
        準備中

      </section>




      {/* Cycle Sequencing反応 */}
      <section>
      <h2 className='section-title' id='Cycle-Sequencing反応' style={{display:"block",paddingTop:"180px",
marginTop:"-180px"}}>Cycle Sequencing反応</h2>
        <h3 className='section-title' id='Cycle-Sequencing反応-反応液の調製' style={{display:"block",paddingTop:"180px",
marginTop:"-180px"}}>反応液の調製</h3>
        <p>
          提出用にフォワードとリバース別々に用意する。
          <br/>
          ※Bigdyeは非常に高価であり、メーカーのプロトコルによると冷凍と解凍を繰り返しすぎるとよくないらしく、冷凍庫から出し入れする分は分注しておくこと。
        </p>
        <ul>
          <li>
            <p>初心者は1サンプル当たり以下の分量で調製する。</p>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr><th>試薬</th><th colspan="2">量（μL）</th>
                  <th>
                    <select onChange={handleCycleSeqTable1Change}>
                      {Array.from({ length: 200 }, (_, i) => (
                        <option key={i} value={i+1}>
                          {i+1}
                        </option>
                      ))}
                    </select>サンプル分（µL）
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr><td>Bigdye</td><td>2.0</td><td rowspan="3">6.4</td><td>{(2.0*CycleSeqTable1).toFixed(1)}</td></tr>
                <tr><td>Buffer</td><td>4.0</td><td>{(4.0*CycleSeqTable1).toFixed(1)}</td></tr>
                <tr><td>各プライマー</td><td>0.4</td><td>{(0.4*CycleSeqTable1).toFixed(1)}</td></tr>
                <tr><td>精製産物</td><td>x*</td><td rowspan="2">13.6</td><td>na</td></tr>
                <tr><td>miliQ</td><td>13.6-x*</td><td>na</td></tr>	
                <tr><th></th><th>合計</th><th>20.0</th><td>{(20.0*CycleSeqTable1).toFixed(1)}</td></tr>	
                *x = 100~150 / (計測濃度)
              </tbody>
              <br/>
              - 濃度が13.0程度以上の場合miliQを加えて薄める。
            </Table>
          </li>
          <li>
            <p>上記の方法は、非常に贅沢な薬品の使い方なので、大量のサンプルを扱う場合や慣れてきたら以下の分量で行う。</p>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr><th>試薬</th><th colspan="2">量（μL）</th>
                  <th>
                    <select onChange={handleCycleSeqTable2Change}>
                      {Array.from({ length: 200 }, (_, i) => (
                        <option key={i} value={i+1}>
                          {i+1}
                        </option>
                      ))}
                    </select>サンプル分（µL）
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr><td>Bigdye</td><td>0.5</td><td rowspan="4">7.2</td><td>{(0.5*CycleSeqTable2).toFixed(1)}</td></tr>
                <tr><td>Buffer</td><td>2.0</td><td>{(2.0*CycleSeqTable2).toFixed(1)}</td></tr>
                <tr><td>各プライマー</td><td>1.2</td><td>{(1.2*CycleSeqTable2).toFixed(1)}</td></tr>
                <tr><td>miliQ</td><td>3.5</td><td>{(3.5*CycleSeqTable2).toFixed(1)}</td></tr>
                <tr><td>精製産物</td><td>2.8*</td><td>2.8</td><td>{(2.8*CycleSeqTable2).toFixed(1)}</td></tr>
                <tr><th></th><th>合計</th><th>10.0</th><td>{(10.0*CycleSeqTable2).toFixed(1)}</td></tr>
              </tbody>
              *標的領域100 bpあたり10–20 ng
            </Table>
          </li>
          <li>
            <p>精製産物の濃度が高いとアニーリングがうまくいかず波形が小さくなるが、大抵、ソフト上の処理で何とかなるので、産物濃度は割と適当でもいい（そもそもNano Dropは精度が低い）。</p>
          </li>
        </ul>
        <h3 className='section-title' id='Cycle-Sequencing反応-PCR' style={{display:"block",paddingTop:"180px", marginTop:"-180px"}}>PCR</h3>
        <p>以下の設定でPCRにかける。尚、プライマーや領域により設定を変える必要はない。</p>
        <Table striped bordered hover variant="dark">
          <thead>
            <tr><th>温度（℃）</th><th>時間</th><th>回数</th></tr>
          </thead>
          <tbody>
            <tr><td>96</td><td>10秒</td><td rowspan="3">25~30 (30) サイクル</td></tr>
            <tr><td>50</td><td>5秒</td></tr>
            <tr><td>60</td><td>4分</td></tr>
            <tr><td>12</td><td>∞</td><td>∞</td></tr>
          </tbody>
        </Table>
        <h3 className='section-title' id='Cycle-Sequencing反応-余剰蛍光物質の除去（精製）' style={{display:"block",paddingTop:"180px", marginTop:"-180px"}}>余剰蛍光物質の除去（精製）</h3>
        <ul>
          <li>
            <p>エタ沈</p>
            <ol>
              <li>Cycle Sequencing産物の入ったPCRチューブに100%エタノールを50 μL、3 M sodium acetateを2.0 μL入れる。</li>
              <li>一晩冷凍庫で寝かせる（15分程度でも可）。</li>
              <li>15,000 rpmで20分間遠心する。</li>
              <li>沈殿を掻き取らないように注意しつつ、上澄みを可能な限り除去する。</li>
              <li>70%エタノールを200 μL入れ、15,000 rpmで5分間遠心する。</li>
              <li>沈殿を掻き取らないように注意しつつ、上澄みを除去する。</li>
              <li>チューブのフタを開けたままサーマルサイクラーに60℃で5分間かけ、エタノールを完全に飛ばす。</li>
              <li>Hi-Diを10-20 μL入れ、沈殿が完全に溶けるようにボルテックスする。</li>
              <li>サーマルサイクラーで95℃で2分。</li>
              <li>95℃で処理後、即座に冷凍庫で急冷する。</li>
              <li>各シーケンサーの提出方法に従って提出する。</li>
            </ol>
          </li>
          <li><p>磁性ビーズ（省略）</p></li>
        </ul>
      </section>


      <br/>
      <br/>
      <p>
        最終更新日：2023/01/20
      </p>
    </>
  )
}