import './HeadNavBar.css';
import {Container, Nav, Navbar, Dropdown, NavDropdown, Offcanvas, Button} from 'react-bootstrap';
import {EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon, LineShareButton, LineIcon, TwitterShareButton, TwitterIcon} from "react-share";
import { useTheme } from '../../ThemeProvider'; // Contextフックをインポート


export default function HeadNavBar() {
  var
  currentViewUrl = window.location.href,
  shereComment = `清水　壮 So Shimizu #Ichneumonoidea #Ichneumonidae #Darwinwasp #DarwinWasps`,
  iconSize = 40;

  const { changeBgColor } = useTheme(); // コンテキストから changeBgColor 関数を取得
  const { bgColor, fontColor } = useTheme(); // Use the bgColor from the context

  return (
    <header>
      {['xxl'].map((expand) => (
        <Navbar key={expand} variant="dark" expand={expand} style={{ backgroundColor: bgColor }} className="mb-3">
        <Container fluid>

          <Navbar.Brand href= { "./" }>
            <img
              src={ "../So_Shimizu.jpg" }
              alt="So Shimizu icon"
              className='HeadMyIcon-img'
            />
          </Navbar.Brand>
          <Navbar.Brand href= { "./" } style={{color:fontColor}}>
            <span className='HeadNavBar-title-txt'>清水　壮 So </span><span style={{"font-variant-caps":"small-caps"}}>Shimizu, </span><span className='HeadNavBar-title-txt'>PhD</span><br/>
            <span className='HeadNavBar-subtitle-txt'>a Japanese Ichneumonologist</span><br/>




          </Navbar.Brand>
          <Navbar.Brand href= { "./" }>
            <span className='share_page_button'>
              <span className='share_button'>
                <EmailShareButton url={[ currentViewUrl ]} quote={[ shereComment ]}><EmailIcon size={ iconSize } round /></EmailShareButton>
              </span>
              <span className='share_button'>
                <FacebookShareButton url={[ currentViewUrl ]} quote={[ shereComment ]}><FacebookIcon size={ iconSize } round /></FacebookShareButton>
              </span>
              <span className='share_button'>
                <TwitterShareButton url={[ currentViewUrl ]} title={[ shereComment ]}><TwitterIcon size={ iconSize } round /></TwitterShareButton>
              </span>
              <span className='share_button'>
                <LineShareButton url={[ currentViewUrl ]} title={[ shereComment ]}><LineIcon size={ iconSize } round /></LineShareButton>
              </span>
            </span>

                        {/* 他の色変更ボタン */}
<Button onClick={() => changeBgColor('rgb(152, 89, 0)', 'blanchedalmond')} style={{ fontSize:"15px", borderColor: "white", borderWidth: "2px", backgroundColor: "rgb(152, 89, 0)" }}>茶</Button>
<Button onClick={() => changeBgColor('black', 'rgb(219, 181, 127)')} style={{ fontSize:"15px", borderColor: "white", borderWidth: "2px", backgroundColor: "black" }}>黒</Button>

          </Navbar.Brand>

          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          <Navbar.Offcanvas id={`offcanvasNavbar-expand-${expand}`} aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}><h4>INDEX</h4></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>

              <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link href= {"/"} >HOME</Nav.Link>

              <NavDropdown title="ABOUT" id="basic-nav-dropdown">
                <b style={{paddingLeft:"15px",color:"white"}}>CV</b>
                  <NavDropdown.Item href= { "/about-me" }>私について</NavDropdown.Item>
                <NavDropdown.Divider />
                <b style={{paddingLeft:"15px",color:"white"}}>RESEARCH</b>
                  <NavDropdown.Item href= { "/about-research-theme" }>研究テーマ</NavDropdown.Item>
                <NavDropdown.Divider />
                <b style={{paddingLeft:"15px",color:"white"}}>ADDITIONAL</b>
                  <NavDropdown.Item href= { "/about-parasitoid-wasps" }>寄生性ハチ類の魅力と課題</NavDropdown.Item>
              </NavDropdown>

              {/*<Nav.Link href= {"/selected-achievements"} >Selected achievements</Nav.Link>*/}
              <NavDropdown title="ACHIEVEMENTS" id="basic-nav-dropdown">
              <NavDropdown.Item href= {"/publications"} >PUBLICATIONS</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href= {"/presentations"} >PRESENTATIONS</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href= {"/new-taxa"} >NEW TAXA</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href= {"/grants"} >GRANTS</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="TIPS & MEMO" id="basic-nav-dropdown">
                <b style={{paddingLeft:"15px",color:"white"}}>MEMO</b>
                  <NavDropdown.Item href= { "/target-journal-list" }>Target journal list</NavDropdown.Item>
                  <NavDropdown.Item href= { "/software-list" }>Software list</NavDropdown.Item>
                  <NavDropdown.Item href= { "/useful-links" }>Useful links</NavDropdown.Item>
                  <NavDropdown.Divider />
                <b style={{paddingLeft:"15px",color:"white"}}>TIPS</b>
                  <NavDropdown.Item href= { "/figure-editing-technique" }>論文図版編集・準備のコツ</NavDropdown.Item>
                  <NavDropdown.Item href= { "/sanger_seqs_protocol"}>サンガー法プロトコル</NavDropdown.Item>
                  <NavDropdown.Item href= { "/molecular-species-delimitation" }>分子種定義解析</NavDropdown.Item>
                  <NavDropdown.Item href= { "/mr-bayes" }>MrBayes</NavDropdown.Item>
                  <NavDropdown.Item href= { "/python_openai_api_introduction"}>PythonでChatGPTを使う：openai api入門</NavDropdown.Item>
                  <NavDropdown.Divider />
              </NavDropdown>
              <NavDropdown title="TOOLS" id="basic-nav-dropdown">
                <NavDropdown.Item href={ "/dna_seq_format_converter" }>DNA sequences formatter</NavDropdown.Item>
              </NavDropdown>

              <Nav.Link href={ "/contact" }>CONTACT</Nav.Link>
            </Nav>
            </Offcanvas.Body>
            </Navbar.Offcanvas>
        </Container>
      </Navbar>
      ))}
    </header>
  );
};