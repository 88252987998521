import './Contact.css';
import icon_email from './src/icon_email.png';
import icon_address from './src/icon_address.png';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ContactIcon from './src/ContactIcon.png';
import {useTheme} from "../../ThemeProvider";

export default function Contact(){
    const { bgColor } = useTheme(); // Use the bgColor from the context

    return (
        <div style={{backgroundColoe:bgColor }}>

            <Breadcrumb style={{zIndex:"9",color:"white"}}>
                <Breadcrumb.Item href="/" style={{zIndex:"9"}}>Home</Breadcrumb.Item>
                <Breadcrumb.Item active style={{zIndex:"9",color:"white"}}>連絡先 Contact</Breadcrumb.Item>
            </Breadcrumb>

            <h1 className='page-type-name'>CONTACT</h1>

<div className='about-me-title-area-container'>
    <img className='about-me-img' src={ ContactIcon } alt="So Shimizu" style={{backgroundColor:"white"}}/>
    <div className='about-me-title-area'>
        <h1 className='page-subtitle'>連絡先</h1>
        <h1 className='page-title'>Contact</h1>
        <br/>
        <p>
        私の連絡先情報です。<br/>
                        学術論文や学会要旨の別刷の請求や昆虫の同定依頼、共同研究などのご相談にお使いください。<br/>
                        その他、私に関する情報はページ下部、フッターからご覧ください。
        </p>
    </div>
</div>

            <div className='main-area'>
                <div className='contact-icon-area'>
                    <img src={ icon_email } alt="email icon" className='contact-icon'/>
                    <div style={{"font-size":"28px","margin-bottom":"4px"}}><b>E-mail</b></div>
                    <div style={{"font-size":"26px"}}>
                        parasitoidwasp.sou # gmail $ com<br/>
                        "#" to "@"<br/>
                        "$" to "."
                    </div>
                </div>

                <div className='contact-icon-area'>
                    <img src={ icon_address } alt="email icon" className='contact-icon'/>
                    <div style={{"font-size":"28px","margin-bottom":"4px"}}><b>Affiriation address</b></div>
                    <div className='page-title-abstract-paragraph' style={{"padding":"8px"}}>
                        同定依頼標本等は以下の住所宛てにお願いいたします。<br/>
                        ※同定依頼の場合は必ず事前にメールにてご相談下さい。
                    </div>
                    <div style={{"font-size":"26px"}}>
                    〒305-8604 茨城県つくば市観音台3-1-3（環研地区つくば研究本館104）<br/>

                    農業・食品産業技術総合研究機構 植物防疫研究部門 基盤防除技術研究領域 越境性・高リスク病害虫対策グループ 学振PD<br/>
                    Division of Core Technology for Pest Control Research, Institute for Plant Protection, National Agriculture and Food Research Organization, Tsukuba, Ibaraki, Japan
                    </div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1836.1723107357836!2d140.1083842449417!3d36.02739686700824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6022735d3b7aa765%3A0x4c21afadfd470ebd!2z6L6y56CU5qmf5qeLIOi-sualreeSsOWig-WkieWLleeglOeptuOCu-ODs-OCv-ODvEluc3RpdHV0ZSBmb3IgQWdyby1FbnZpcm9ubWVudGFsIFNjaWVuY2VzLCBOQVJP!5e1!3m2!1sen!2sjp!4v1674211305779!5m2!1sen!2sjp"
                        style={{
                            "width":"100%",
                            "max-width":"1100",
                            "height":"200%",
                            "max-height":"950",
                            "border":"0",
                            "padding":"20px"
                        }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        title="Google map"
                    ></iframe>
                </div>
                <div style={{"height":"700px"}}></div>
            </div>
        </div>
    );
};