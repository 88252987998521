import React, { useState } from 'react';
import {Breadcrumb} from 'react-bootstrap'
import icon from './icon.png';

export default function DNAseqFormatConverter() {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('Results will be shown in this section.');
  const [inputFormat, setInputFormat] = useState('fasta');
  const [outputFormat, setOutputFormat] = useState('phylip');

  // ファイルを読み込んでテキストを設定する関数
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setInputText(e.target.result);
      };
      reader.readAsText(file);
    }
  };

  // ファイルをダウンロードする関数
  const downloadFile = () => {
    const blob = new Blob([outputText], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `converted.${outputFormat}`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

   // ファイルとテキストエリアをリセットする関数
  const resetFields = () => {
    setInputText('');
    setOutputText('');
  };

  // sanitizeSequenceName 関数を追加
  function sanitizeSequenceName(name) {
    // 不適切な文字をアンダースコアに置き換える正規表現
    const invalidCharsRegExp = /[^a-zA-Z0-9_]/g;
    const sanitizedName = name.replace(invalidCharsRegExp, '_');
    return sanitizedName;
  }

  const phylipToFasta = (phylipText) => {
    const lines = phylipText.split('\n').map((line) => line.trim());
    const [headerLine, ...sequenceLines] = lines;
    const [numSequences, sequenceLength] = headerLine.split(/\s+/);
    const sequences = [];

    for (let i = 0; i < numSequences; i++) {
      const [name, sequence] = sequenceLines[i].split(/\s+/);
      sequences.push(`>${name}\n${sequence}`);
    }

    return sequences.join('\n');
  };

  const nexusToFormat = (nexusText, outputFormat) => {
    // NexusからFastaまたはPhylipへの変換ロジックを実装
    // ここでは、単純な例を示しており、実際のNexusフォーマットに合わせて調整が必要です。
    if (outputFormat === 'fasta') {
      // NexusからFastaへの変換
      // nexusTextを解析してFastaに変換するコードを追加
    } else if (outputFormat === 'phylip') {
      // NexusからPhylipへの変換
      // nexusTextを解析してPhylipに変換するコードを追加
    }
  };

  const convertFormat = () => {
    if (inputFormat === 'fasta' && outputFormat === 'phylip') {
      const phylipText = fastaToPhylip(inputText);
      setOutputText(phylipText);
    } else if (inputFormat === 'phylip' && outputFormat === 'fasta') {
      const fastaText = phylipToFasta(inputText);
      setOutputText(fastaText);
    } else if (inputFormat === 'nexus' && (outputFormat === 'fasta' || outputFormat === 'phylip')) {
      const convertedText = nexusToFormat(inputText, outputFormat);
      setOutputText(convertedText);
    }
  };

   // シーケンス名の正規化を適用
   const fastaToPhylip = (fastaText) => {
    const lines = fastaText.split('\n').map((line) => line.trim());
    const sequences = [];
    let currentSequence = null;

    for (let line of lines) {
      if (line.startsWith('>')) {
        if (currentSequence) {
          sequences.push(currentSequence);
        }
        // シーケンス名を正規化
        const name = sanitizeSequenceName(line.slice(1));
        currentSequence = { name, sequence: '' };
      } else if (currentSequence) {
        currentSequence.sequence += line;
      }
    }

    if (currentSequence) {
      sequences.push(currentSequence);
    }

    const numSequences = sequences.length;
    const sequenceLength = sequences[0].sequence.length;
    const phylipLines = [`${numSequences} ${sequenceLength}`];

    for (let i = 0; i < sequences.length; i++) {
      phylipLines.push(`${sequences[i].name} ${sequences[i].sequence}`);
    }

    return phylipLines.join('\n');
  };


  return (
    <>
      <Breadcrumb style={{zIndex:"9",color:"white"}}>
        <Breadcrumb.Item href="/" style={{zIndex:"9"}}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active style={{zIndex:"9",color:"white"}}>DNAシーケンスフォーマット変換ツール</Breadcrumb.Item>
      </Breadcrumb>

      <h1 className='page-type-name'>TOOLS</h1>
      <div className='about-me-title-area-container'>
        <img className='about-me-img' src={ icon } alt="So Shimizu" style={{backgroundColor:"white"}}/>
        <div className='about-me-title-area'>
          <h1 className='page-subtitle'>DNA sequence format converter</h1>
          <h1 className='page-title'>DNAシーケンスフォーマット変換ツール</h1>
          <br/>
          <p>
            Fastaとphylips間のフォーマットを変換するためのオンラインツールです。
          </p>
        </div>
      </div>

      <div>
        <h2 className='section-title'>DNA Sequence Format Converter</h2>
        <p>
          Paste your fasta or phylips text in the below text area or select your sequence file.
        </p>
        <div>
          <label>Input Format: </label>
          <select
            value={inputFormat}
            onChange={(e) => setInputFormat(e.target.value)}
            style={{borderRadius:"15px",width:"120px",textAlign:"center"}}
          >
            <option value="fasta">Fasta</option>
            <option value="phylip">Phylip</option>
          </select>
        </div>

        <div>
          <label>Output Format: </label>
          <select
            value={outputFormat}
            onChange={(e) => setOutputFormat(e.target.value)}
            style={{borderRadius:"15px",width:"120px",textAlign:"center"}}
          >
            <option value="fasta">Fasta</option>
            <option value="phylip">Phylip</option>
          </select>
        </div>

        {/* ファイル選択用のinput要素 */}
        <input
          type="file"
          accept=".txt,.fasta,.phy,.fas"
          onChange={handleFileInputChange}
          style={{borderRadius:"15px",textAlign:"center"}}
        />
        <textarea
          placeholder={`Enter ${inputFormat.toUpperCase()} format here`}
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          rows={10}
          cols={40}
          style={{
            height:"400px",
            width:"100%",
            fontSize:"18px",
            lineHeight:"1.0em",
            borderRadius:"15px",
            padding:"10px",
            marginTop:"10px",
          }}
        />

        {/* 変換開始ボタン */}
        <button
          onClick={convertFormat}
          style={{borderRadius:"15px",width:"120px",textAlign:"center"}}
        >Convert</button>
        

        {/* 結果表示エリア */}
        <h2 className='section-title'>Converted Format Output</h2>
        <p>
          If the sequence name contained invalid characters, it was normalised.
        </p>
        {/* ダウンロードボタン */}
        <button
          onClick={downloadFile}
          style={{borderRadius:"15px",width:"220px",textAlign:"center"}}
        >Download result</button>
        {/* リセットボタン */}
        <button
          onClick={resetFields}
          style={{borderRadius:"15px",width:"120px",textAlign:"center"}}
        >Reset</button>
        <div>
          <pre
            style={{
              height:"600px",
              width:"100%",
              backgroundColor:"rgb(100, 72, 0)",
              lineHeight:"1.0em",
              borderRadius:"15px",
              padding:"10px",
              marginTop:"10px",
            }}
          >
            {outputText}
          </pre>
        </div>

      </div>


      <p>
        最終更新日：2023/12/15
      </p>
    </>
  )
}