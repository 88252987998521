import {Breadcrumb} from 'react-bootstrap';
import OphioneullusImg from "./src/Ophioneullus.png"

export default function AboutParasitoidWasps() {
    return (
        <>
            <Breadcrumb style={{zIndex:"9",color:"white"}}>
                <Breadcrumb.Item href="/" style={{zIndex:"9"}}>Home</Breadcrumb.Item>
                <Breadcrumb.Item active style={{zIndex:"9",color:"white"}}>寄生性ハチ類の魅力と課題</Breadcrumb.Item>
            </Breadcrumb>

            <h1 className='page-type-name'>ABOUT</h1>

            <div className='about-me-title-area-container'>
                <img className='about-me-img' src={ OphioneullusImg } alt="So Shimizu"/>
                <div className='about-me-title-area'>
                    <h1 className='page-subtitle'>寄生性ハチ類</h1>
                    <h1 className='page-title'>PARASITOID WASPS</h1>
                    <br/>
                    <p>
                        ハチ目<br/>
                        Hymenoptera
                    </p>
                </div>
            </div>

            <h2 className='section-title'>ようこそ！寄生性ハチ類の世界へ</h2>

            <div className='Home-one-line-container'>


                <div className='CommentForVisitor'>
                    <p>
                        寄生性ハチ類はミツバチやスズメバチ、アリなどと同じハチ目の仲間です。
                        チョウ目やコウチュウ目といったような昆虫界のスター的グループに比べるとマイナーなグループではありますが、
                        寄生性ハチ類は高いポテンシャルを持った魅力的ですごい昆虫のひとつです。
                    </p>
                    <span className='txt-underline'>圧倒的な種多様性</span>
                    <p>
                        寄生性ハチ類は個々の宿主への適応の過程で、高度に多様化してきました。
                        宿主となる昆虫などの分類群自体が極めて高度に多様化しているため、
                        それを利用する寄生性ハチ類も全動物の中でもトップクラスの種多様性を示すと考えられています。
                        よって、生物多様性に関する様々な研究において注目を集めています。
                    </p>
                    <span className='txt-underline'>驚きの形態的多様性と美しさ</span><br/>
                    <p>
                        宿主への適応の過程で、寄生性ハチ類に驚きの形態的な多様性が生じています。
                        その中には、宝石のような輝きを放つものや飴細工のような繊細さをもつものなど、
                        ため息が出るような美しい形態をしたものも多く含まれます。
                    </p>
                    <span className='txt-underline'>ダーウィンも驚いた⁉驚愕の生態</span><br/>
                    <p>
                        飼い殺し型内部寄生性ハチ類の幼虫は宿主を生かしたまま宿主体内を食い尽くし、
                        最後には宿主の体を突き破り外に出てきます。
                        こうした、まるでエイリアンのような驚きの生活史は、進化学の父、チャールズ・ダーウィンも書簡の中で特筆しています。
                        その他にも、宿主の行動を操作するものやウイルスなどを用いて宿主の免疫を低下させるものなど、
                        非常に高度化した方法で寄生生活を行っています。
                        よって、生物間相互作用の仕組みと進化などの研究において、
                        生態学や進化生物学的視点からも注目されています。
                    </p>
                    <span className='txt-underline'>生態系サービス（調節サービス）</span><br/>
                    <p>
                        寄生性ハチ類は宿主となる生物の数をコントロールする重要な調節サービスを担ってくれています。
                        宿主の中には農林業上の重要な天敵も含まれます。
                        よって、土着天敵や天敵素材（生物農薬）として、
                        農林学的にも古くから注目を集めてきています。
                    </p>

                </div>
            </div>


            {/* 寄生性ハチ類の山積みの課題 */}
            <div className='Home-one-line-container'>
                <div className='CommentForVisitor'>
                <h2 className='section-title'>寄生性ハチ類の山積みの課題</h2>
                    <p>
                        寄生性ハチ類はポテンシャルが高くてすごい！と紹介しましたが、
                        そのポテンシャルを引き出し、私たちの生活に彼らを役立てるにはまだまだ山積みの課題が残っています。
                    </p>
                    <p>
                        <ul>
                            <li>
                                <b>種名を調べられない</b><br/>
                                研究において、全世界の人々が研究材料を正確に共通認識出来て、その結果を再現できることは非常に重要です。
                                生物を扱う研究においては、研究対象となる生物の種類を正確に特定（同定）出来ることが、全ての研究のスタートラインとなります。
                                しかし、寄生性ハチ類に関しては、種名を特定するための資料はまだ少ない状態であり、
                                寄生性ハチ類を用いた研究のハードルは非常に高い状態です。
                            </li>
                            <li>
                                <b>膨大な多様性と研究者不足</b><br/>
                                寄生性ハチ類は動物トップクラスの多様性を持っていると言われていますが、
                                十分に研究が進んでいないためその実態は不明です。
                                家庭菜園や道路の生垣、都市公園などの非常に身近な環境から草原、原生的な森林まで、
                                様々な場所にまだまだ認識されていない未知の種（新種）が存在しています。
                                しかし、研究難易度の高さや認知度の低さから、
                                寄生性ハチ類を対象とした研究者数はその多様性に対して十分であるとは言えない状態です。
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
        </>
    );
};