import {Breadcrumb, Button, Card} from 'react-bootstrap';
import UsefulLinkIcon from './UsefulLinkIcon.png';

export default function UsefulLinks() {
    /*
    論文執筆支援
    ハチの情報
    公募情報収集
    配列の形式変換
    */
    return (
        <>
          <Breadcrumb style={{zIndex:"9",color:"white"}}>
            <Breadcrumb.Item href="/" style={{zIndex:"9"}}>Home</Breadcrumb.Item>
            <Breadcrumb.Item active style={{zIndex:"9",color:"white"}}>便利なリンク集 Useful links</Breadcrumb.Item>
          </Breadcrumb>

          <h1 className='page-type-name'>MEMO</h1>

<div className='about-me-title-area-container'>
    <img className='about-me-img' src={ UsefulLinkIcon } alt="So Shimizu" style={{backgroundColor:"white"}}/>
    <div className='about-me-title-area'>
        <h1 className='page-subtitle'>便利なリンク集</h1>
        <h1 className='page-title'>Useful link list</h1>
        <br/>
        <p>
        私が研究や日常でよく使うウェブアプリなどへのリンク集です。
        </p>
    </div>
</div>



        <h2 className='section-title'>英文翻訳</h2>
        <ul>
          <li>
            <a href="https://www.deepl.com/translator" target="_blank" rel="noreferrer"><b style={{fontSize:"24px"}}>DeepL</b></a><br/>
            便利なAI翻訳。Google翻訳とかより良い結果が得られます。
          </li>
          <li>
            <a href="https://chat.openai.com/" target="_blank" rel="noreferrer"><b style={{fontSize:"24px"}}>chatGPT</b></a><br/>
            今話題のchatGPTです。
          </li>
        </ul>

        <h2 className='section-title'>英文校閲</h2>
        <ul>
          <li>
            <a href="https://www.editage.jp/" target="_blank" rel="noreferrer"><b style={{fontSize:"24px"}}>editage</b></a><br/>
            数年前まであまりいい結果が来ないことも多かったですが、最近は納得の校閲をして頂いていて大変お世話になっています。
          </li>
        </ul>

        <h2 className='section-title'>公募情報収集</h2>
        <ul>
          <li>
            <a href="https://jrecin.jst.go.jp/seek/SeekJorSearch?fn=1&dt=2&page=1&sort=0&keyword_and=&keyword_or=&keyword_not=&bg1=00006&sm1=00001&bg2=&sm2=&bg3=&sm3=&bg4=&sm4=&bg5=&sm5=&bg6=&sm6=&bgCode1=00006&smCode1=00001&bgCode2=&smCode2=&bgCode3=&smCode3=&bgCode4=&smCode4=&bgCode5=&smCode5=&bgCode6=&smCode6=&jobform=&jobterm=&dispcount=50" target="_blank" rel="noreferrer"><b style={{fontSize:"24px"}}>Jrec-in portal</b></a><br/>
            公募戦士の味方Jrec-inです。敢えてトップページではなく私がよくみる基礎生物学分野の検索先表示にしてあります。
            個々の大学の採用ページなどを巡回する必要がないのでとても助かっています。
          </li>
          <li>
            <b style={{fontSize:"24px"}}>日本応用動物昆虫学会 人材募集</b> ※SSL化されていないリンクのためリンクは貼っていません<br/>
            応動昆の公募情報ページです。
            昆虫関連の公募情報の収集に役立ちます。
          </li>
        </ul>

        <h2 className='section-title'>系統解析関連ウェブアプリ</h2>
        <ul>
          <li>
            <a href="https://www.phylo.org/" target="_blank" rel="noreferrer"><b style={{fontSize:"24px"}}>CIPRES </b></a><br/>
            サーバー上にほぼ全ての最近の系統解析で用いられる解析ソフトがあります。
            大規模な解析などの時には重宝しています。
            系統解析をよくする方は課金の価値があると思います（私は課金しています）。
          </li>
          <li>
            <b style={{fontSize:"24px"}}>ALTER</b> ※SSL化されていないリンクのためリンクは貼っていません<br/>
            アラインメントや同一ハプロタイプの除去の際に便利です。
          </li>
          <li>
            <b style={{fontSize:"24px"}}>Phylogeny.fr</b> ※SSL化されていないリンクのためリンクは貼っていません<br/>
            様々な系統解析ツールが利用可能です。
            個人的にはシーケンスファイルのフォーマットコンバーターを非常によく利用しています。
          </li>
          <li>
            <a href="https://mafft.cbrc.jp/alignment/server/" target="_blank" rel="noreferrer"><b style={{fontSize:"24px"}}>MAFFT</b></a><br/>
            代表的な多重配列アラインメントソフトのウェブアプリです。
          </li>
          <li>
            <b style={{fontSize:"24px"}}>PhyML</b> ※SSL化されていないリンクのためリンクは貼っていません<br/>
            PhyMLのウェブアプリです。
          </li>
          <li>
            <a href="https://www.bioinformatics.org/sms2/index.html" target="_blank" rel="noreferrer"><b style={{fontSize:"24px"}}>Sequence Manipulation Suite (SMS)</b></a><br/>
            シーケンスを扱うためのあらゆるウェブアプリが提供されていていつもお世話になっています。
          </li>
          <li>
            <a href="https://species.h-its.org/ptp/" target="_blank" rel="noreferrer"><b style={{fontSize:"24px"}}>species delimitation server</b></a><br/>
            bPTPを用いて種定義解析を実行できるウェブアプリです。
          </li>
        </ul>






        </>
    )
}