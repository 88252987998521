import React from 'react'
import {Breadcrumb} from 'react-bootstrap'
import icon from './icon.png';

import "highlight.js/styles/github.css";
import hljs from "highlight.js";
import { marked } from "marked";
import { useEffect } from "react";
import {useTheme} from "../../../ThemeProvider";

const code_install_splits = `
  \`\`\`typescript
  // splitsパッケージのインストールRコード
  install.packages("splits", repos="http://R-Forge.R-project.org")
  \`\`\`
`;

const code_run_gmyc = `
  \`\`\`typescript
  // splitsの読み込みから実行までのRコード
  library(splits)
  樹形の名前 <- read.nexus(file="ファイル名.拡張子")
  適当な結果名 <- gmyc(樹形の名前)
  plot(適当な結果名)
  \`\`\`
`;

const codeGitClonePTP = `
  \`\`\`typescript
  // PTP環境構築ディレクトリでgit cloneを実行
  git clone "https://github.com/zhangjiajie/PTP.git"
  \`\`\`
`;

const code1PTP = `
  \`\`\`typescript
  // Anaconda Promptのエラー発生時の出力例
  IOError: [Errno 13] Permission denied: 該当ファイル名
  \`\`\`
`;

const code2PTP = `
  \`\`\`typescript
  // Pythonのソースコード内
  open(fout + ".PTPPartitions.txt", "w")
  open(fout + ".PTPllh.txt", "w")
  open(fout + ".llh.pdf", "w")
  open(fout + ".PTPPartitonSummary.txt", "w")
  open(fout + ".PTPhSupportPartition.txt", "w")
  open(fout + ".PTPhSupportPartition.txt.png/svg", "w")
  open(fout + ".PTPMLPartition.txt", "w")
  open(fout + ".PTPMLPartition.txt.png/svg", "w")
  \`\`\`
`;

const code3PTP = `
  \`\`\`typescript
  // Pythonのソースコード内
  open(fout + "/PTPPartitions.txt", "w")
  open(fout + "/PTPllh.txt", "w")
  open(fout + "/llh.pdf", "w")
  open(fout + "/PTPPartitonSummary.txt", "w")
  open(fout + "/PTPhSupportPartition.txt", "w")
  open(fout + "/PTPhSupportPartition.txt.png/svg", "w")
  open(fout + "/PTPMLPartition.txt", "w")
  open(fout + "/PTPMLPartition.txt.png/svg", "w")
  \`\`\`
`;

export default function TipsSpeciesDelimitation() {
  useEffect(() => {
    hljs.highlightAll();
  });

  const { bgColor } = useTheme();

  return (
    <>
      <Breadcrumb style={{zIndex:"9",color:"white"}}>
        <Breadcrumb.Item href="/" style={{zIndex:"9"}}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active style={{zIndex:"9",color:"white"}}>分子種定義</Breadcrumb.Item>
      </Breadcrumb>

      <h1 className='page-type-name'>TIPS</h1>
      <div className='about-me-title-area-container'>
        <img className='about-me-img' src={ icon } alt="So Shimizu" style={{backgroundColor:"white"}}/>
        <div className='about-me-title-area'>
          <h1 className='page-subtitle'>分子種定義</h1>
          <h1 className='page-title'>Molecular species delimitations</h1>
          <br/>
          <p>
            最近多くの研究で用いられるようになってきた分子種定義解析に関する簡単なメモです。
          </p>
        </div>
      </div>

      <h2 className='section-title'>はじめに</h2>
      <p>
        分子種定義には樹形ベースと距離ベースの手法があります。
        距離ベースの手法は、塩基配列の置換数のギャップに基づいてクラスタリングが実行され、
        従来の「○○％離れてたら別種」みたいな種定義の仕方に近い手法です。
        一方で、樹形ベースの手法は、系統樹の樹形と枝長に基づいてクラスタリングが実行され、
        より系統的にまとまりのある種定義が出来ます。
      </p>
      <p>
        分子種定義解析でクラスタリングされる種は単にOTUではなく、「molecular operational taxonomic units (MOTUs)」と言われます。
      </p>

      <h2 className='section-title'>代表的な距離ベース分子種定義手法</h2>
      <ul>
        <li>
          <h2 className='section-title-sub'>ABGD (Automatic Barcode Gap Discovery for primary species delimitation)</h2>
          <p>
            最もよく使われている手法だと思います。入力されたデータセット内の塩基配列のギャップと相対的な閾値からMOTUsを定義していきます。
            ABGDのウェブアプリ（<a href={"https://bioinfo.mnhn.fr/abi/public/abgd/abgdweb.html"} target={"_blank"} rel="noreferrer">https://bioinfo.mnhn.fr/abi/public/abgd/abgdweb.html</a>）上で非常に容易に使用できるので、
            導入のハードルも低いのが人気の理由かもしれません。
          </p>
        </li>
      </ul>

      <h2 className='section-title'>代表的な樹形ベース分子種定義手法</h2>
      <p>
        個人的なおすすめ順に紹介していきます。
      </p>
      <ul>
        <li>
          <h2 className='section-title-sub'>PTP & bPTP</h2>
          <p>
            良く用いられるGMYCよりも容易に走られる上にGMYCと同等またはそれ以上の結果を得られます。
            種分化や分岐イベントを置換数として考慮して種の境界を定義していくPTPモデルと系統的位置を考慮して種が定義され、
            いわゆる系統分類学的なMOTUsが得られます。
            解析はウェブアプリ（<a href="https://species.h-its.org/ptp/" target="_blank" rel="noreferrer">species delimitation server</a>）上で非常に簡単に実行できます。
            bPTPでは最尤法とベイズ法両方に基づいた定義された種の支持率が得られるため、
            MOTUsの頑強さの評価の参考になります。
            小規模なデータセットであれば、ウェブアプリ上で実行可能なMCMCの上限、10万世代で十分な収束が得られます。
            しかし、大規模なデータセットの場合はウェブアプリではなく、スタンドアロン版をローカルにクローンしてより多くのMCMC世代を実行する必要があります。
            <p className="insert-column-container" style={{backgroundColor:bgColor}}>
              <b>Tips</b><br/>
              <p>
                現在（2022年）のバージョンでは、Windows環境下では最後のoutputファイルの出力時に以下のようなエラーが生じ、プログラムが終了してしまいます。
              </p>
              {/* コードブロック */}
              <div>
                <table style={{width:"100%",backgroundColor:"black"}}>
                  <tr style={{borderBlockEndWidth:"5px",borderBottomColor:"gray"}}><td>
                    <p style={{paddingLeft:"15px"}}>
                      <span style={{fontWeight:"bold"}}>
                        Anaconda Prompt
                      </span>
                    </p>
                  </td></tr>
                </table>
                <div style={{width:"100%",overflowX:"scroll",backgroundColor:"black"}}>
                  <table style={{whiteSpace:"nowrap"}}>
            <tr style={{backgroundColor:"black"}}>
              <td style={{verticalAlign:"top", paddingTop:"18px", paddingLeft:"10px",color:"gray",lineHeight:"30px"}}>
                  1<br/>2
              </td>
              <td style={{verticalAlign:"top"}}>
                <div dangerouslySetInnerHTML={{ __html: marked( code1PTP ) }}></div>
              </td>
            </tr>
          </table>
        </div>
              </div>
              <p>
                このエラーはアウトプットディレクトリへのアクセスが実行できないことが原因で生じています。
                よって、正しくアウトプットディレクトリを指定出来るようにソースコードに修正を加える必要があります。
              </p>
              <p>
                PTPのgithubページまたはREADMEの中に目を通すと以下のファイルが出力されることが分かります。
              </p>
              <ul>
                <li>outputname.PTPPartitions.txt</li>
                <li>outputname.PTPllh.txt</li>
                <li>outputname.llh.pdf（収束具合の確認に必要）</li>
                <li>outputname.PTPPartitonSummary.txt（定義された種の事後確率の要約）</li>
                <li>outputname.PTPhSupportPartition.txt（ベイズ法で最も指示された種）</li>
                <li>outputname.PTPhSupportPartition.txt.png/svg（ベイズ法で最も指示された種を示した図）</li>
                <li>outputname.PTPMLPartition.txt（最尤法により定義された種）</li>
                <li>outputname.PTPMLPartition.txt.png/svg（最尤法により定義された種を示した図）</li>
              </ul>
              <p>
                これらのファイルがソースコード内でどのように生成・参照されているかを確認すると以下のようなパスの指定が行われていることが分かります。
              </p>
                    {/* コードブロック */}
      <div>
        <table style={{width:"100%",backgroundColor:"black"}}>
          <tr style={{borderBlockEndWidth:"5px",borderBottomColor:"gray"}}><td>
            <p style={{paddingLeft:"15px"}}>
              <span style={{fontWeight:"bold"}}>
                source code
              </span>
            </p>
          </td></tr>
        </table>
        <div style={{width:"100%",overflowX:"scroll",backgroundColor:"black"}}>
          <table style={{whiteSpace:"nowrap"}}>
            <tr style={{backgroundColor:"black"}}>
              <td style={{verticalAlign:"top", paddingTop:"18px", paddingLeft:"10px",color:"gray",lineHeight:"30px"}}>
                  1<br/>2<br/>3<br/>4<br/>5<br/>6<br/>7<br/>8<br/>9
              </td>
              <td style={{verticalAlign:"top"}}>
                <div dangerouslySetInnerHTML={{ __html: marked( code2PTP ) }}></div>
              </td>
            </tr>
          </table>
        </div>
      </div>
              <p>
                エラーの原因はパスを「.」で指定していることであり、
                「.」を「/」に修正するだけでエラーを回避し、正しい出力を得られるようになります。
                使い慣れたコードエディタを用いてソースコード内で各アウトプットファイル名を検索し、
                「.」を「/」に置換すれば作業は完了です。
              </p>
               {/* コードブロック */}
      <div>
        <table style={{width:"100%",backgroundColor:"black"}}>
          <tr style={{borderBlockEndWidth:"5px",borderBottomColor:"gray"}}><td>
            <p style={{paddingLeft:"15px"}}>
              <span style={{fontWeight:"bold"}}>
                source code
              </span>
            </p>
          </td></tr>
        </table>
        <div style={{width:"100%",overflowX:"scroll",backgroundColor:"black"}}>
          <table style={{whiteSpace:"nowrap"}}>
            <tr style={{backgroundColor:"black"}}>
              <td style={{verticalAlign:"top", paddingTop:"18px", paddingLeft:"10px",color:"gray",lineHeight:"30px"}}>
                  1<br/>2<br/>3<br/>4<br/>5<br/>6<br/>7<br/>8<br/>9
              </td>
              <td style={{verticalAlign:"top"}}>
                <div dangerouslySetInnerHTML={{ __html: marked( code3PTP ) }}></div>
              </td>
            </tr>
          </table>
        </div>
      </div>
              <p>
                その他にも、オリジナルだとMCMCサンプリング1回あたりの経過時間が分からず、
                解析にどのくらいの時間が必要なのかの計算が出来ません。
                そこで、私は時間ログを出力し、総サンプリング数と現在までのサンプリング数から残りの所要時間を計算し、
                表示させるコードを追加し、解析の利便性を高めています。
                こうした、Tipsに関しても後日記事を作成出来たらしますが、
                ご興味がある方は個人的にお問い合わせいただけましたら、
                協力をさせていただきます。
              </p>
            </p>
          </p>

        </li>
        <li>
          <h2 className='section-title-sub'>GMYC</h2>
          <p>
            最もよく使われている手法のひとつで、Rで簡単に実行できます。
            しかし、解析にはキャリブレーションされた樹形、ultrametric treeが必須となります。
            通常の樹形も強制的にapeのchronos関数を用いればultrametric tree化出来ますがお勧めできません。
            この解析は、入力樹形の構築方法に結果が少なからず敏感であり、
            小規模なデータセットにしか適応できない上、
            BEASTなどを使いこなし適した樹形ファイルを用意する必要がある点で導入のハードルは比較的高いと言えます。
            入力樹形構築の際に用いられるモデルの影響などに関しては多くの研究で検証が行われており、
            私の研究対象であるヒメバチ科ではSchwarzfeld & Sperling (2015) などが参考になります。
            種定義解析用の樹形構築時にはstrict clock modelがしばしば使われているようですが、
            やはりuncorrelated lognormal relaxed clock modelの方が良い結果が得られることが多いようです。
            また種定義解析時には、一般的に種レベルの系統関係の推定時などに用いられるYuleモデルよりも、
            有効集団サイズ解析などに用いられるcoalescentモデルの方がいい結果が得られることが多いらしいです。
            <p className="insert-column-container" style={{backgroundColor:bgColor}}>
              <b>Tips</b><br/>
              基本的なGMYCの実行方法は以下の通りです。
              <ol>
                <li>
                  <b>樹形の用意</b><br/>
                  通常の系統樹では実行できません。
                  BEAST等で作成したキャリブレーション樹形（ultrametric tree）を用います。
                </li>
                <li>
                  <b>使用Rパッケージのインストール</b><br/>
                  パッケージ、apeとsplitsを用います。
                  splitsのインストールは以下のように行います。
                                 {/* コードブロック */}
      <div>
        <table style={{width:"100%",backgroundColor:"black"}}>
          <tr style={{borderBlockEndWidth:"5px",borderBottomColor:"gray"}}><td>
            <p style={{paddingLeft:"15px"}}>
              <span style={{fontWeight:"bold"}}>
                R script
              </span>
            </p>
          </td></tr>
        </table>
        <div style={{width:"100%",overflowX:"scroll",backgroundColor:"black"}}>
          <table style={{whiteSpace:"nowrap"}}>
            <tr style={{backgroundColor:"black"}}>
              <td style={{verticalAlign:"top", paddingTop:"18px", paddingLeft:"10px",color:"gray",lineHeight:"30px"}}>
                  1<br/>2
              </td>
              <td style={{verticalAlign:"top"}}>
                <div dangerouslySetInnerHTML={{ __html: marked( code_install_splits ) }}></div>
              </td>
            </tr>
          </table>
        </div>
      </div>
                </li>
                <li>
                  <b>基本的な実行</b><br/>
                  以下のコードで実行可能です。
                  {/* コードブロック */}
      <div>
        <table style={{width:"100%",backgroundColor:"black"}}>
          <tr style={{borderBlockEndWidth:"5px",borderBottomColor:"gray"}}><td>
            <p style={{paddingLeft:"15px"}}>
              <span style={{fontWeight:"bold"}}>
                R script
              </span>
            </p>
          </td></tr>
        </table>
        <div style={{width:"100%",overflowX:"scroll",backgroundColor:"black"}}>
          <table style={{whiteSpace:"nowrap"}}>
            <tr style={{backgroundColor:"black"}}>
              <td style={{verticalAlign:"top", paddingTop:"18px", paddingLeft:"10px",color:"gray",lineHeight:"30px"}}>
                  1<br/>2<br/>3<br/>4<br/>5
              </td>
              <td style={{verticalAlign:"top"}}>
                <div dangerouslySetInnerHTML={{ __html: marked( code_run_gmyc ) }}></div>
              </td>
            </tr>
          </table>
        </div>
      </div>
                </li>
              </ol>
            </p>
          </p>
        </li>
        <li>
          <h2 className='section-title-sub'>mPTP</h2>
          <p>
            ウェブアプリで基本的な解析は可能。詳細なメモは準備中。
          </p>

        </li>
        <li>
          <h2 className='section-title-sub'>BPP</h2>
          <p>
            ウェブアプリで基本的な解析は可能。詳細なメモは準備中。
          </p>
        </li>
      </ul>






        <h2 className='section-title'>さいごに</h2>
        <p>
          分子種定義はCO1遺伝子系統樹などに基づき行われることが多く、
          必ずしも生物種総体としての形質から得られている結果ではありませんし、
          種を定義する際の1要素に過ぎません。
          よって、分子種定義に絶対的な信頼をおき分類を進めるのではなく、
          形態形質や生態などの他の要素と比較しながら慎重に分類を進めていく必要があります。
          しかし、やはり隠蔽種の発見のきっかけや、分類が遅れているグループの多様性の規模の推定などにおいては非常にパワフルな手法です。
          目的に合わせて、適切に結果を解釈・利用していく必要があります。
        </p>

        <br/>
        <br/>
        <p>
          最終更新日：2023/01/20
        </p>
    </>
  )
}