export default function TopFade() {
    return(
        <div style={{
            "margin-top":"59px",
            "min-width": "460px",
            "width":"100vw",
            "height":"90px",
            "position":"fixed",
            "display":"flex",
            "background":"linear-gradient(rgb(152, 89, 0) 60%,transparent)",
            "opacity":"0.8",
            "z-index":"9"
        }} />
    )
}