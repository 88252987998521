import React from 'react'
import {Breadcrumb} from 'react-bootstrap'
import icon from './icon.png';

import "highlight.js/styles/github.css";
import hljs from "highlight.js";
import { marked } from "marked";
import { useEffect } from "react";

export default function PythonClass() {
  useEffect(() => {
    hljs.highlightAll();
  });
  return (
    <>
      <Breadcrumb style={{zIndex:"9",color:"white"}}>
        <Breadcrumb.Item href="/" style={{zIndex:"9"}}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active style={{zIndex:"9",color:"white"}}>Pythonのclassの基礎的なメモ</Breadcrumb.Item>
      </Breadcrumb>

      <h1 className='page-type-name'>TIPS</h1>
      <div className='about-me-title-area-container'>
        <img className='about-me-img' src={ icon } alt="So Shimizu" style={{backgroundColor:"white"}}/>
        <div className='about-me-title-area'>
          <h1 className='page-subtitle'>Pythonのclassの基礎的なメモ</h1>
          <h1 className='page-title'>Pythonのclassの基礎的なメモ</h1>
          <br/>
          <p>
            ExaBayesを用いたベイズ系統推定に関する簡単なメモです。
          </p>
        </div>
      </div>

      <h2 className='section-title'>classとは？</h2>
      <p>
        Pythonはオブジェクトと呼ばれる要素から成るオブジェクト指向プログラミング言語です。
        大規模な開発などの際には、複雑な様々な処理を扱うことになり、全ての処理をまとめて書くと長い上、ネストの深いコードとなりコードを書いた本人以外には難解でメンテナンスが難しいコードになってしまいます。
        そうした問題に対して、各処理をオブジェクトとしてまとめることで（カプセル化）、可読性の高いコードとなりメンテナンスが容易になります。
        classはそうしたカプセル化を実現する方法です。
      </p>

      {/* コードブロック */}
      <div>
        <table style={{width:"100%",backgroundColor:"black"}}>
          <tr style={{borderBlockEndWidth:"5px",borderBottomColor:"gray"}}><td>
            <p style={{paddingLeft:"15px"}}>
              <span style={{fontWeight:"bold"}}>
                Pythonにおけるclassの基本構文
              </span>
            </p>
          </td></tr>
        </table>
        <div className='code-area-wrap'>
          <table style={{whiteSpace:"nowrap"}}>
            <tr style={{backgroundColor:"black"}}>
              <td style={{verticalAlign:"top", paddingTop:"18px", paddingLeft:"10px",color:"gray",lineHeight:"30px"}}>
                  1<br/>2<br/>3<br/>4
              </td>
              <td style={{verticalAlign:"top"}}>
                <div dangerouslySetInnerHTML={{ __html: marked( PythonClass_basic ) }}></div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <h2 className='section-title'>Windows環境におけるMrBayesの実行環境構築</h2>
      <p>
        公式のダウンロードに関して書いてあるページにもある通り、コンパイル済みのダウンロードできるMrBayesは非並列かつGPUも利用できないバージョンです。
        小規模なデータセットの解析の場合は、それでもいいのですが実際に行う解析では時間がかかり過ぎるので非現実的な場合が多いです。
        そこでマルチスレッドの並列版（MPI版）やBEAGLEを用いたGPUによる補助を利用したMrBayesを実行する環境を作る必要があります。
        しかし、Windowsネイティブ環境下では簡単にMPI版をコンパイルすることが出来ませんので、Windows subsystem for linux (WSL)を利用します。
      </p>
      <p>
        WSL環境は非常に簡単です。
      </p>
      <ol>
        <li>windows storeの検索ウィンドウに「WSL」などと入力し検索をかける</li>
        <li>ubuntuを選択しインストール</li>
      </ol>
      <p>
        ザックリとした手順は以上のようになります。
        詳しくはググってみてください。
      </p>

      <h2 className='section-title'>WSL上でのMPI版MrBayesのコンパイル（メイクする）</h2>
      <p>
        WSLの環境構築が完了したらMPI版のMrBayesをメイクします。
        尚、私のPCに積んであるGPUがAMD製でありドライバの相性がBeagleとあまりよくないのでBeagleに関してはここでは割愛します。
      </p>



      <h2 className='section-title'>MrBayesの実行コマンドを事前に用意する</h2>
      <p>
        解析を行う際の実行コマンドはその都度、コマンドラインに入力してもいいです。
        しかし、入力・コピペの際に生じ得るミスや手間を回避するためにも実行コマンドを事前にファイルとして用意しておく事をおすすめします。
      </p>

      {/* コードブロック */}
      <div>
        <table style={{width:"100%",backgroundColor:"black"}}>
          <tr style={{borderBlockEndWidth:"5px",borderBottomColor:"gray"}}><td>
            <p style={{paddingLeft:"15px"}}>
              file name: <span style={{fontWeight:"bold"}}>
                mb.bay
              </span>
            </p>
            <p style={{paddingLeft:"15px"}}>
              MrBayesの実行ファイル（私はmb.bayなどのファイル名と拡張子を使用）の中身の例です。<br/>
              nexusフォーマット内では"[]"でコメントアウトとしてメモが利用できます。
            </p>
          </td></tr>
        </table>
        <div style={{width:"100%",overflowX:"scroll",backgroundColor:"black"}}>
          <table style={{whiteSpace:"nowrap"}}>
            <tr style={{backgroundColor:"black"}}>
              <td style={{verticalAlign:"top", paddingTop:"18px", paddingLeft:"10px",color:"gray",lineHeight:"30px"}}>
                  1<br/>2<br/>3<br/>4<br/>5<br/>6<br/>7<br/>8<br/>9<br/>10<br/>11<br/>12<br/>13<br/>14<br/>15<br/>16<br/>17<br/>18<br/>19<br/>20<br/>21<br/>22<br/>23<br/>24<br/>25<br/>26<br/>27<br/>28<br/>29<br/>30<br/>31<br/>32<br/>33<br/>34<br/>35<br/>36<br/>37<br/>38<br/>39<br/>40<br/>41<br/>42<br/>43<br/>44<br/>45<br/>46<br/>47<br/>48
              </td>
              <td style={{verticalAlign:"top"}}>
                <div dangerouslySetInnerHTML={{ __html: marked( mb_bay_file_example_1 ) }}></div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <h2 className='section-title'>MrBayesの実行</h2>
      <p>
        上記までの作業を終えたら実際に実行します。
      </p>
      {/* コードブロック */}
      <div>
        <table style={{width:"100%",backgroundColor:"black"}}>
          <tr style={{borderBlockEndWidth:"5px",borderBottomColor:"gray"}}><td>
            <p style={{paddingLeft:"15px"}}>
              <span style={{fontWeight:"bold"}}>
                ubuntu on WSL
              </span>
            </p>
          </td></tr>
        </table>
        <div style={{width:"100%",overflowX:"scroll",backgroundColor:"black"}}>
          <table style={{whiteSpace:"nowrap"}}>
            <tr style={{backgroundColor:"black"}}>
              <td style={{verticalAlign:"top", paddingTop:"18px", paddingLeft:"10px",color:"gray",lineHeight:"30px"}}>
                  1<br/>2<br/>3<br/>4<br/>5<br/>6<br/>7<br/>8<br/>9<br/>10<br/>11<br/>12<br/>13<br/>14<br/>15<br/>16<br/>17<br/>18<br/>19<br/>20<br/>21<br/>22<br/>23<br/>24<br/>25<br/>26<br/>27<br/>28<br/>29<br/>30<br/>31<br/>32<br/>33
              </td>
              <td style={{verticalAlign:"top"}}>
                <div dangerouslySetInnerHTML={{ __html: marked( run_mb_on_wsl ) }}></div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <h2 className='section-title'>結果の集約と合意樹の出力</h2>
      <p>
        MCMCの収束の判定については後日加筆します。
        現段階では、収束後のMCMCの結果の集約と合意樹の出力に関してのみメモします。
      </p>
      {/* コードブロック */}
      <div>
        <table style={{width:"100%",backgroundColor:"black"}}>
          <tr style={{borderBlockEndWidth:"5px",borderBottomColor:"gray"}}><td>
            <p style={{paddingLeft:"15px"}}>
              <span style={{fontWeight:"bold"}}>
                ubuntu on WSL
              </span>
            </p>
          </td></tr>
        </table>
        <div style={{width:"100%",overflowX:"scroll",backgroundColor:"black"}}>
          <table style={{whiteSpace:"nowrap"}}>
            <tr style={{backgroundColor:"black"}}>
              <td style={{verticalAlign:"top", paddingTop:"18px", paddingLeft:"10px",color:"gray",lineHeight:"30px"}}>
                  1<br/>2<br/>3<br/>4<br/>5<br/>6<br/>7<br/>8<br/>9
              </td>
              <td style={{verticalAlign:"top"}}>
                <div dangerouslySetInnerHTML={{ __html: marked( sump_burnin ) }}></div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <h2 className='section-title'>参考</h2>
      <ul>
        <li><a href={"https://gensoft.pasteur.fr/docs/mrbayes/3.2.7/Manual_MrBayes_v3.2.pdf"} rel="noreferrer" target="_blank">MrBayes version 3.2 Manual:Tutorials and Model Summaries</a></li>
        <li><a href={"http://www2.tba.t-com.ne.jp/nakada/takashi/bayes/mrb312.html"} rel="noreferrer" target="_blank">MrBayes 3.1.2 を用いた系統解析 on Windows</a></li>
        <li><a href={"https://www.ikushimo.com/news/2008/01/18.html"} rel="noreferrer" target="_blank">幾霜::残日録::2008/01/18 (金)</a></li>
        <li><a href={"https://www.fifthdimension.jp/wiki.cgi?generation=4&page=MrBayes%A4%CB%A4%E8%A4%EB%A5%D9%A5%A4%A5%B8%A5%A2%A5%F3%B7%CF%C5%FD%BF%E4%C4%EA&action=SOURCE"} rel="noreferrer" target="_blank">MrBayesによるベイジアン系統推定</a></li>
        <li><a href={"http://treethinkers.org/wp-content/uploads/2013/01/MrBayes3.2.2Primer_ModSelec.pdf"} rel="noreferrer" target="_blank">MrBayes 3.2.2 Primer (with a focus on model selection)</a></li>
      </ul>

        <br/>
        <br/>
        <p>
          最終更新日：2023/05/25
        </p>
    </>
  )
}

// basic class
const PythonClass_basic = `
\`\`\`python
class ClassName(): # 任意のclass名を定義
  def __init__(self, ): # インスタンス作成に必要なコンストラクタ。selfはインスタンス自身。
    something
\`\`\`
`;
// mb実行ファイルの例
const mb_bay_file_example_1 = `
\`\`\`typescript
[ execute the multiple sequence alignment file はじめに多重配列アラインメントファイルを呼び出すコマンド ]
exe msa.nex;

// [ define start tree 初期系統樹ブロック：ランダムツリーを使用する場合は不要 ]
// begin trees;
//   tree iqTree = "ここにNEWICKフォーマットの初期系統樹を入力";
// end;

[ mrbayes command block MrBayesコマンドブロック ]
begin mrbayes;
	[ set optimal substitution model block 置換モデルの設定。]
	// ここにPartitionFinderやModelFinderなどで選んだ最適なモデルをコピペする。

	[ set link or unlink branch length ミトコンドリアや核遺伝子など複数領域の解析等の際、必要に応じて枝長のlink/unlinkを設定。]
	unlink brlens=(all); // 全ての枝長をリンクさせない場合の例。

	[ set outgroup 外群の設定。]
	outgroup "terminal_ID_for_outgroup";

	// [ set start tree 初期系統樹の設定。ランダムツリーを用いる場合は不要。]
	// startvals tau = iqTree;

	[ start log and set log file name ログの開始とファイル名の設定。]
	log
      start
      filename = mcmc.log;

	[ set parameters of mcmc run using "mcmcp" command "mcmcp"コマンドによる実行コマンド設定。]
	// 直実行するよりも事前にコマンド設定したほうが追加解析などが必要となったときに何かと都合が良い。
	mcmcp
        // append = yes [ 途中から解析を再開する際はこれを追加する ]
        // starttree = current [ 初期系統樹の呼び出し。ランダムツリーを使用する場合は不要。 ]
		ngen = 10000000
		nruns = 2
		nchains = 8
		NSwaps = 28
		temp = 0.1
		printfreq = 1000
		samplefreq = 1000
		savebrlens = yes
		filename = output;

	[ run mcmc using "mcmc" command "mcmc"コマンドによるMCMCの実行。]
	mcmc;

	[ stop mcmc ログの終了。]
	log stop;
end;
\`\`\`
`;
// run mb
const run_mb_on_wsl = `
\`\`\`typescript
// execute MrBayes
mpirun -np 8 mb // "-np"で使用するスレッド数を指定。設定できるスレッド数は実行するMCMCの鎖数とPCのCPUのスペックに依存する。"nchains = 8"の場合はは8。

// example of executed MrBayes
beignet-opencl-icd: no supported GPU found, this is probably the wrong opencl-icd package for this hardware
(If you have multiple ICDs installed and OpenCL works, you can ignore this message)


                            MrBayes 3.2.7a x86_64

                      (Bayesian Analysis of Phylogeny)

                             (Parallel version)
                         (8 processors available)

              Distributed under the GNU General Public License


               Type "help" or "help <command>" for information
                     on the commands that are available.

                   Type "about" for authorship and general
                       information about the program.

// 用意したmb.bayファイルを実行する。
MrBayes > exe mb.bay

// example of a part of the MCMC log
Chain results (10000000 generations requested):
0       -- [-74524.056] (-74524.056) [...14 remote chains...] 
1000 -- (-69888.846) (-70126.618) [...14 remote chains...] -- 61:06:18
// 解析終了まで残り61時間6分18秒待機が必要だと分かる。
// 途中で強制停止する場合は"CTRL+C"を入力する。
\`\`\`
`;

const sump_burnin = `
\`\`\`typescript
MrBayes > sump relburnin = no  burnin = XXXXX
// XXXXXに棄却する樹形数を入力する。
// デフォルトで"relburnin = yes"になっており、この設定下ではburinを設定しても無視され最初の25%の樹形が棄却される。
// "relburnin = no"に設定することで、"burnin"の設定が有効となる。
// 例えば10,000,000世代のMCMCで1,000世代ごとに樹形をサンプリングした場合、合計10,000樹形が得られる。
// 十分に収束した後半の5,000樹形から合意樹を作成する場合、前半の5,000樹形を棄却するので"burnin = 5,000"となる。

// sumpコマンドで出力された集約結果を確認し問題なければ合意樹を作成する。
MrBayes > sumt relburnin = no  burnin = XXXXX
\`\`\`
`;
