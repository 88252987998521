import React, { createContext, useState, useContext, useEffect } from 'react';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const [themeColors, setThemeColors] = useState({
    bgColor: localStorage.getItem('bgColor') || 'black',
    fontColor: localStorage.getItem('fontColor') || 'rgb(219, 181, 127)',
  });

  useEffect(() => {
    // bgColorが変更されたらローカルストレージに保存
    localStorage.setItem('bgColor', themeColors.bgColor);
    localStorage.setItem('fontColor', themeColors.fontColor);
  }, [themeColors.bgColor,themeColors.fontColor]); // 依存配列にbgColorを追加


  const changeBgColor = (pageColor, fontColor) => {
    setThemeColors({
      bgColor: pageColor,
      fontColor: fontColor,
    });
  };

  return (
    <ThemeContext.Provider value={{ ...themeColors, changeBgColor }}>
      {children}
    </ThemeContext.Provider>
  );
};
