import React from 'react'
import {Breadcrumb} from 'react-bootstrap'
import icon from './icon.png';

import "highlight.js/styles/github.css";
import hljs from "highlight.js";
import { marked } from "marked";
import { useEffect } from "react";

export default function PythonOpenaiApiIntroduction() {
  useEffect(() => {
    hljs.highlightAll();
  });
  return (
    <>
      <Breadcrumb style={{zIndex:"9",color:"white"}}>
        <Breadcrumb.Item href="/" style={{zIndex:"9"}}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active style={{zIndex:"9",color:"white"}}>PythonでChatGPTを使う：openai api入門</Breadcrumb.Item>
      </Breadcrumb>

      <h1 className='page-type-name'>TIPS</h1>
      <div className='about-me-title-area-container'>
        <img className='about-me-img' src={ icon } alt="So Shimizu" style={{backgroundColor:"white"}}/>
        <div className='about-me-title-area'>
          <h1 className='page-subtitle'>PythonでChatGPT</h1>
          <h1 className='page-title'>PythonでChatGPTを使う：openai api入門</h1>
          <br/>
          <p>
            Pythonを用いてopenai apiを使ってGPTプロンプトを実行する方法のメモです。
          </p>
        </div>
      </div>

      {/* イントロ */}
      <section>
        <p>
          ChatGPTの登場以来、急速に人工知能（AI）が身近な存在となってきました。
        </p>
        <p>
          一般の生活面における活用はもちろんのこと、AIは研究生活においても様々な変化をもたらしています。例えば、英語の翻訳や校正の自動化、データや原稿のフォーマット調整の効率化、プログラムコードの改善の提案などにおいて、AIが活用されています。
        </p>
        <p>
          AIは活用出来れば無駄な作業を減らして研究や娯楽の時間を確保し、より素晴らしい生活を送ることに役立ちます。しかし、提供されている既存サービスのみでは、AIを活用しても自身の需要を十分に満たす成果を得られないことがあります。
        </p>
        <p>
          そこで、ここではChatGPTを運営しているOpenAIが提供するOpenAI APIサービスをpythonから利用する基礎を紹介します。
        </p>
        <p>
          このAPIをpythonから自由に利用する方法を習得すれば、pythonプログラムの中に生成AIを組み込んだ自作プログラムの作成が可能になります。それにより、自然な言語生成、文章の要約、質問応答システムの構築、注目の論文調査など、さまざまなタスクをAIに任せることができます。
        </p>
        <p>
          このページでは、OpenAI APIの基本的な使い方から応用までを解説していますので、研究関係者だけではなく、AIを活用したいと考えている方や、新しい技術に興味がある方の参考になれば幸いです。
        </p>
      </section>

      {/* 実行環境 */}
      <h2 className='section-title'>筆者の動作確認環境</h2>
      <section>
        <ul>
          <li>windows 11</li>
          <li>python v.3.11.4</li>
        </ul>
      </section>

      {/* 実行コード例 */}
      <h2 className='section-title'>実行コード例</h2>
      <section>
        <p>
          はじめに、pythonでopenai apiを実行するコード例を示します。
        </p>
        {/* Pythonでopenai apiを使う基本的なコードブロック */}
        <div>
        <table style={{width:"100%",backgroundColor:"black"}}>
          <tr style={{borderBlockEndWidth:"5px",borderBottomColor:"gray"}}><td>
            <p style={{paddingLeft:"15px"}}>
              <span style={{fontWeight:"bold"}}>
                python
              </span>
            </p>
          </td></tr>
        </table>
        <div className='code-area-wrap'>
          <table style={{whiteSpace:"nowrap"}}>
            <tr style={{backgroundColor:"black"}}>
              <td style={{verticalAlign:"top", paddingTop:"18px", paddingLeft:"10px",color:"gray",lineHeight:"30px"}}>
                  1<br/>2<br/>3<br/>4<br/>5<br/>6<br/>7<br/>8<br/>9<br/>10<br/>11<br/>12<br/>13<br/>14<br/>15<br/>16<br/>17<br/>18<br/>19<br/>20<br/>21<br/>22<br/>23<br/>24<br/>25<br/>26<br/>27<br/>28<br/>29<br/>30<br/>31<br/>32<br/>33<br/>34<br/>35<br/>36<br/>37<br/>38<br/>39
              </td>
              <td style={{verticalAlign:"top"}}>
                <div dangerouslySetInnerHTML={{ __html: marked( code_openai_api_with_python ) }}></div>
              </td>
            </tr>
          </table>
        </div>
        </div>
      </section>

      <h2 className='section-title'>簡単な解説</h2>
      <section>
        <ul>
          <li>
            8行目：openai apiのAPIキーは、<a href='https://openai.com/product' target="_blank" rel="noreferrer">OpenAIのAPIページ</a>から取得します。トップページの「GET STARTED」から、ログインまたはアカウントを作成し、ユーザーページからAPIキーを生成することが出来ます。openai apiのアカウント作成などに関しては、後日メモを作成します。
          </li>
          <li>
            16行目：現在利用出来るGPTのモデルは、OpenAIのユーザーページから確認できます。モデルごとに生成出来るものや、学習データのタイプ・期間、利用価格が異なりますので、自身の目的に合ったモデルを利用してください。
          </li>
        </ul>
      </section>

      <h2 className='section-title'>参考</h2>
      <ul>
        <li><a href={"https://github.com/openai/openai-python"} rel="noreferrer" target="_blank">The official Python library for the OpenAI API</a></li>
      </ul>

        <br/>
        <br/>
        <p>
          最終更新日：2023/11/25
        </p>
    </>
  )
}

// WSL上でのmbのメイク
const code_openai_api_with_python = `
\`\`\`python
# openaiのモジュールをインポートする。
import openai
import httpx

# openaiのクライアントを設立する。
client = openai.OpenAI(
    # APIキーの設定
    api_key = "ここに自身のAPIキーを入力する",
    # 必要に応じてタイムアウトを設定する。数字は秒数。
    timeout = httpx.Timeout(100),
    # 読み込み書き込み時間等を分けてタイムアウトの設定も可能。
    #timeout = httpx.Timeout(15.0, read = 5.0, write = 10.0, connect = 3.0)
)

# 用途や予算に合ったモデルを指定する。
model = "gpt-4"

# 実行したいプロンプト。
prompt = "あなたはプロのサイエンスライターです。学術論文の内容をわかりやすく伝える為に役立つコツを教えてください。"

# タイムアウトなどの例外発生時のためtry/except内でopenapiにプロンプトを投げる。
for i in range(5):
    try:
        res = client.chat.completions.create(
            model = model,
            messages = [
                #{"role": "system", "content": "You are a helpful assistant."},
                {"role": "user", "content": prompt}
            ],
            # 乱数の温度を高くすると返答にバラツキが出るらしい。
            temperature = 1.0,
        )
        # json形式で結果が返ってくるので、その中から本文を取り出して表示する。
        res_content = resp.choices[0].message.content
        print(res_content)
        break
    except:
        print("何かしらのエラーが発生したのでやり直します。")
        continue
\`\`\`
`;
// mb実行ファイルの例
const mb_bay_file_example_1 = `
\`\`\`typescript
[ execute the multiple sequence alignment file はじめに多重配列アラインメントファイルを呼び出すコマンド ]
exe msa.nex;

// [ define start tree 初期系統樹ブロック：ランダムツリーを使用する場合は不要 ]
// begin trees;
//   tree iqTree = "ここにNEWICKフォーマットの初期系統樹を入力";
// end;

[ mrbayes command block MrBayesコマンドブロック ]
begin mrbayes;
	[ set optimal substitution model block 置換モデルの設定。]
	// ここにPartitionFinderやModelFinderなどで選んだ最適なモデルをコピペする。

	[ set link or unlink branch length ミトコンドリアや核遺伝子など複数領域の解析等の際、必要に応じて枝長のlink/unlinkを設定。]
	unlink brlens=(all); // 全ての枝長をリンクさせない場合の例。

	[ set outgroup 外群の設定。]
	outgroup "terminal_ID_for_outgroup";

	// [ set start tree 初期系統樹の設定。ランダムツリーを用いる場合は不要。]
	// startvals tau = iqTree;

	[ start log and set log file name ログの開始とファイル名の設定。]
	log
      start
      filename = mcmc.log;

	[ set parameters of mcmc run using "mcmcp" command "mcmcp"コマンドによる実行コマンド設定。]
	// 直実行するよりも事前にコマンド設定したほうが追加解析などが必要となったときに何かと都合が良い。
	mcmcp
        // append = yes [ 途中から解析を再開する際はこれを追加する ]
        // starttree = current [ 初期系統樹の呼び出し。ランダムツリーを使用する場合は不要。 ]
		ngen = 10000000
		nruns = 2
		nchains = 8
		NSwaps = 28
		temp = 0.1
		printfreq = 1000
		samplefreq = 1000
		savebrlens = yes
		filename = output;

	[ run mcmc using "mcmc" command "mcmc"コマンドによるMCMCの実行。]
	mcmc;

	[ stop mcmc ログの終了。]
	log stop;
end;
\`\`\`
`;
// run mb
const run_mb_on_wsl = `
\`\`\`typescript
// execute MrBayes
mpirun --use-hwthread-cpus mb
//または、mpirun -np 8 mb 
// "-np"で使用するスレッド数を指定。設定できるスレッド数は実行するMCMCの鎖数とPCのCPUのスペックに依存する。"nchains = 8"の場合はは8。

// example of executed MrBayes
beignet-opencl-icd: no supported GPU found, this is probably the wrong opencl-icd package for this hardware
(If you have multiple ICDs installed and OpenCL works, you can ignore this message)


                            MrBayes 3.2.7a x86_64

                      (Bayesian Analysis of Phylogeny)

                             (Parallel version)
                         (8 processors available)

              Distributed under the GNU General Public License


               Type "help" or "help <command>" for information
                     on the commands that are available.

                   Type "about" for authorship and general
                       information about the program.

// 用意したmb.bayファイルを実行する。
MrBayes > exe mb.bay

// example of a part of the MCMC log
Chain results (10000000 generations requested):
0       -- [-74524.056] (-74524.056) [...14 remote chains...] 
1000 -- (-69888.846) (-70126.618) [...14 remote chains...] -- 61:06:18
// 解析終了まで残り61時間6分18秒待機が必要だと分かる。
// 途中で強制停止する場合は"CTRL+C"を入力する。
\`\`\`
`;

const sump_burnin = `
\`\`\`typescript
MrBayes > sump relburnin = no  burnin = XXXXX
// XXXXXに棄却する樹形数を入力する。
// デフォルトで"relburnin = yes"になっており、この設定下ではburinを設定しても無視され最初の25%の樹形が棄却される。
// "relburnin = no"に設定することで、"burnin"の設定が有効となる。
// 例えば10,000,000世代のMCMCで1,000世代ごとに樹形をサンプリングした場合、合計10,000樹形が得られる。
// 十分に収束した後半の5,000樹形から合意樹を作成する場合、前半の5,000樹形を棄却するので"burnin = 5,000"となる。

// sumpコマンドで出力された集約結果を確認し問題なければ合意樹を作成する。
MrBayes > sumt relburnin = no  burnin = XXXXX
\`\`\`
`;
