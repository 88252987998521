import {useState, useEffect} from 'react';
import './Publications.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import ZKIcon from './ZKIcon.png';
import PaperData from './PublicationsData.json';
import {useTheme} from "../../../ThemeProvider";
import LineChartComponent from "./chart";


// PaperCard コンポーネント
function PaperCard({ paper, index, language }) {
  const { bgColor, fontColor } = useTheme();
  //Modal.defaultStyles.content.backgroundColor = bgColor;


  const [modalShow, setModalShow] = useState(false);

  const title = language === 'English' ? (paper.pub_title_en || paper.pub_title_jp) : (paper.pub_title_jp || paper.pub_title_en);
const journalName = language === 'English' ? (paper.pub_journal_name_en || paper.pub_journal_name_jp) : (paper.pub_journal_name_jp || paper.pub_journal_name_en);
const reviewStatus = paper.pub_review ? 'Reviewed' : 'Not reviewed';

  return (
    <>
      <Card className="peer-reviewed-publications-card" style={{backgroundColor:bgColor,color:fontColor}}>
        <Card.Header style={{ fontSize: "18px" }}>
          <span style={{fontSize:"20px"}}>
            <b> No. {index + 1}</b> | {paper.pub_date} | {paper.my_position}
          </span>
        </Card.Header>
        <Card.Body>
          <p className={`${reviewStatus === 'Reviewed' ? 'reviewed_badge' : 'not_reviewed_badge'}`}>{reviewStatus}</p>
          <p><b style={{ fontSize: "22px" }} dangerouslySetInnerHTML={{ __html:title}} /></p>
          <p style={{ fontSize: "18px" }}>{paper.pub_author.join(', ')}</p>
          <p style={{ fontSize: "18px" }}>
            <i><b>{journalName}</b></i> {paper.pub_vol}: {paper.pub_page}
          </p>
          <p style={{textAlign:"right"}}>
            <Button variant="primary" onClick={() => setModalShow(true)}>Read more...</Button>
          </p>
        </Card.Body>
      </Card>

      <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)} >
        <Modal.Header closeButton style={{backgroundColor:bgColor, color:fontColor}}>
          <Modal.Title id="contained-modal-title-vcenter" style={{fontSize:"30px",width:"100%"}} >
            <p className={`${reviewStatus === 'Reviewed' ? 'reviewed_badge' : 'not_reviewed_badge'}`}>{reviewStatus}</p>
            <span dangerouslySetInnerHTML={{ __html:title}}/>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{backgroundColor:bgColor, color:fontColor}}>
          <h3 className='section-title'>AUTHORS</h3>
          <p>{paper.pub_author.join(', ')}</p>
        <h3 className='section-title'>JOURNAL</h3>
        <p>
          <b><i>{journalName}</i></b>
          <Button variant="primary" href={paper.pub_doi} target="_blank" style={{ marginRight: "10px" }} disabled>DOI link</Button>
          <Button variant="primary" href={paper.pub_rg} target="_blank" disabled>PDF via RG</Button>
        </p>
        {paper.pub_abstract && (
          <>
            <h3 className='section-title'>ABSTRACT</h3>
            <p dangerouslySetInnerHTML={{ __html:paper.pub_abstract}} />
          </>
        )}
        {paper.pub_keywords.join(', ') && (
          <>
            <h3 className='section-title'>KEYWORDS</h3>
            <p dangerouslySetInnerHTML={{ __html:paper.pub_keywords.join(', ')}} />
          </>
        )}
        <h3 className='section-title'>HOW TO CITE</h3>
        <p>{paper.pub_author.join(', ')} ({new Date(paper.pub_date).getFullYear()}) <span dangerouslySetInnerHTML={{ __html:paper.pub_title_en}} />. <i><b>{journalName}</b></i> {paper.pub_vol}: {paper.pub_page}. {paper.pub_doi}</p>
        {paper.comment && (
          <>
            <h3 className='section-title'>COMMENT</h3>
            <p dangerouslySetInnerHTML={{ __html:paper.comment}} />
          </>
        )}
      </Modal.Body>
      <Modal.Footer style={{backgroundColor:bgColor, color:fontColor}}>
        <Button onClick={() => setModalShow(false)}>Close</Button>
      </Modal.Footer>
      </Modal>
    </>
  );
}

function PaperNumTable( {reviewdNum, nonReviewedNum, totalPaperNum} ) {
  return(
    <table style={{ textAlign: "center", borderCollapse: "collapse", width:"100%" }}>
      <tr>
        <th style={{ borderTop: "4px solid rgb(228, 174, 97)", borderBottom: "3px solid rgb(228, 174, 97)", paddingLeft: "10px", paddingRight: "10px" }}>Category</th>
        <th style={{ borderTop: "4px solid rgb(228, 174, 97)", borderBottom: "3px solid rgb(228, 174, 97)", paddingLeft: "10px", paddingRight: "10px" }}>Count</th>
      </tr>
      <tr>
        <td style={{paddingLeft:"10px",paddingRight:"10px"}}>Peer reviewed</td><td>{reviewdNum}</td>
      </tr>
      <tr>
        <td style={{paddingLeft:"10px",paddingRight:"10px"}}>Non reviewed</td><td>{nonReviewedNum}</td>
      </tr>
      <tr>
        <td style={{ borderBottom: "4px solid rgb(228, 174, 97)" }}>Total</td><td style={{ borderBottom: "4px solid rgb(228, 174, 97)" }}>{totalPaperNum}</td>
      </tr>
    </table>
  );
}



export default function PeerReviewedPublications({bgColor }) {

  const [sortOrder, setSortOrder] = useState('降順');
  const [reviewFilter, setReviewFilter] = useState('all'); // レビューのフィルタリング状態（'all', 'true', 'false'）
  const [language, setLanguage] = useState('English');
  const [displayedItemCount, setDisplayedItemCount] = useState(0); // 表示されるアイテム数

  const setLanguageTo = (newLanguage) => {
    setLanguage(newLanguage);
  };
  // 並べ替え順を切り替える関数
  const setSortOrderTo = (newOrder) => {
    setSortOrder(newOrder);
  };

  // フィルタリングの状態を切り替える関数
  const toggleReviewFilter = (newFilter) => {
    setReviewFilter(newFilter);
  };

  // PaperData をフィルタリングと並べ替え
  const filteredAndSortedPaperData = [...PaperData]
    .filter(paper => {
      if (reviewFilter === 'all') return true;
      return reviewFilter === 'true' ? paper.pub_review : !paper.pub_review;
    })
    .sort((a, b) => {
      const dateA = new Date(...a.pub_date.split('-'));
      const dateB = new Date(...b.pub_date.split('-'));
      return sortOrder === '降順' ? dateB - dateA : dateA - dateB;
    });

    // 表示されるアイテム数を更新
  useEffect(() => {
    setDisplayedItemCount(filteredAndSortedPaperData.length);
  }, [filteredAndSortedPaperData]);

  // true と false のカウントを格納するオブジェクト
  const reviewCounts = PaperData.reduce((acc, cur) => {
    acc[cur.pub_review ? 'true' : 'false'] = (acc[cur.pub_review ? 'true' : 'false'] || 0) + 1;
    return acc;
  }, {true: 0, false: 0});
const reviewdNum = reviewCounts.true;
const nonReviewedNum = reviewCounts.false;
const totalPaperNum = PaperData.length;



  return (
    <div style={{backgroundColoe:bgColor }}>

    <Breadcrumb style={{zIndex:"9",color:"white"}}>
      <Breadcrumb.Item href="/" style={{zIndex:"9"}}>Home</Breadcrumb.Item>
      <Breadcrumb.Item active style={{zIndex:"9",color:"white"}}>学術論文 Publications</Breadcrumb.Item>
    </Breadcrumb>

    <h1 className='page-type-name'>PUBLICATIONS</h1>

    <div className='about-me-title-area-container'>
      <img className='about-me-img' src={ ZKIcon } alt="So Shimizu"/>
      <div className='about-me-title-area'>
        <h1 className='page-subtitle'>学術論文</h1>
        <h1 className='page-title'>Publications</h1>
        <br/>
        <p>
          <PaperNumTable reviewdNum={reviewdNum} nonReviewedNum={nonReviewedNum} totalPaperNum={totalPaperNum}/>
        </p>
      </div>
    </div>

    <p>
      各論文情報を掲載したカード内のボタンをクリックすることで、詳細な情報を見ることが出来ます。オープンアクセスで無い論文の別刷PDF請求は、RGアカウントをお持ちの方はRG経由で簡単に行うことが出来ます。もし、RGアカウントをお持ちで無い場合は、メールやSNSのメッセージなどにて請求をお願いいたします。※掲載時および最新のJournal Citation Reports掲載のIFを表記しています。これは、私が現在求職中の身であり、公募によっては業績リストにIFの記載が求められることが多々あり、その都度調べるのが大変だからという理由です。
    </p>

    <LineChartComponent />

    <Dropdown>
        表示言語：<Dropdown.Toggle variant="info" id="language-dropdown" style={{ width: "150px",margin:"6px" }}>{language}</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setLanguageTo('English')}>English</Dropdown.Item>
          <Dropdown.Item onClick={() => setLanguageTo('Japanese')}>Japanese</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

    <Dropdown>
      査読有無：<Dropdown.Toggle variant="success" id="dropdown-basic" style={{width:"150px",margin:"6px"}}>
        {reviewFilter === 'all' ? 'All' : (reviewFilter === 'true' ? 'Reviewed' : 'Not Reviewed')}</Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => toggleReviewFilter('all')}>All</Dropdown.Item>
        <Dropdown.Item onClick={() => toggleReviewFilter('true')}>Reviewed</Dropdown.Item>
        <Dropdown.Item onClick={() => toggleReviewFilter('false')}>Not Reviewed</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

    <Dropdown>
        並べ替え：<Dropdown.Toggle variant="info" id="sort-order-dropdown" style={{ width: "150px",margin:"6px" }}>{sortOrder}</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setSortOrderTo('昇順')}>昇順</Dropdown.Item>
          <Dropdown.Item onClick={() => setSortOrderTo('降順')}>降順</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <p>表示件数：<b style={{ fontSize:"25px" }}>{displayedItemCount}</b> 件</p>

    <div className="peer-reviewed-publication-container">
      {filteredAndSortedPaperData.map((paper, index) => (
        <PaperCard key={`${index}-${language}`} paper={paper} index={index} language={language} />
      ))}
    </div>
    </div>
    );
};