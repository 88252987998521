import {Breadcrumb} from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import TargetJournalIcon from './TargetJournalIcon.png';
import {useTheme} from "../../../ThemeProvider";

export default function TargetJournalList() {
    const { fontColor } = useTheme();

    return (
        <>
        <Breadcrumb style={{zIndex:"9",color:"white"}}>
            <Breadcrumb.Item href="/" style={{zIndex:"9"}}>Home</Breadcrumb.Item>
            <Breadcrumb.Item active style={{zIndex:"9",color:"white"}}>学術誌情報メモ Target journals</Breadcrumb.Item>
        </Breadcrumb>

        <h1 className='page-type-name'>MEMO</h1>

<div className='about-me-title-area-container'>
    <img className='about-me-img' src={ TargetJournalIcon } alt="So Shimizu" style={{backgroundColor:"white"}}/>
    <div className='about-me-title-area'>
        <h1 className='page-subtitle'>投稿候補雑誌リスト</h1>
        <h1 className='page-title'>Target journal list</h1>
        <br/>
        <p>
        私がいつか投稿してみたい憧れの雑誌やこれまでに投稿した雑誌などに関してテーブル形式でリスト化しています。
        </p>
    </div>
</div>
        <p>
            各テーブルのフィールド名部分をクリックするとそのカラムを並べ替えすることが出来ます。
            さらに、フィールド名の右端にあるボタンを展開すると、並び替えの方法の選択や表示検索フィルター、表示するカラムの設定なども可能です。
            尚、IDは主キーとして記入順に振ってあるだけなので深い意味はありません。
        </p>
        <p>
            適宜情報の追加や更新を行っていますが誤りやお勧めの雑誌があったらご教示頂けますとうれしいです。
        </p>

        <div style={{ height: '920px', width: '100%' }}>
            <h2 className='section-title-sub'>雑誌リスト Journal list</h2>
            <DataGrid
                style={{
                    color:fontColor,
                    fontSize:'18px',
                }}
                rows={rows}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[15]}
            />
        </div>
        </>
    )
}

const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'journalName', headerName: 'Journal name', width: 400 },
    { field: 'journalNameAbbreviation', headerName: 'Abbreviation', width: 200 },
    { field: 'publisher', headerName: 'Publisher', width: 250 },
    { field: 'IF2021', headerName: 'Impact factor 2021', width: 200 },
    { field: 'pureTaxonomy', headerName: 'Pure taxonomy', width: 170 },
    { field: 'myPaper', headerName: 'My paper', width: 130 },
    { field: 'remarks', headerName: 'Remarks', width: 1000 },
];
const rows = [
    {
        id: 1, journalName:'Molecular Phylogenetics and Evolution', journalNameAbbreviation:'Mol. Phylogenet. Evol.',
        publisher: 'Elsevier', IF2021: 5.019, pureTaxonomy: '✕', myPaper:'Not yet.',
        remarks:'生物全般の系統学や進化学に関する論文が掲載される。それらの研究の中での記載であれば記載を伴う論文も掲載される。',
    },
    {
        id: 2, journalName:'Systematic Entomology', journalNameAbbreviation:'Syst. Entomol.',
        publisher: 'Wiley', IF2021: 4.841, pureTaxonomy: '✕', myPaper:'Not yet.',
        remarks:'昆虫や近縁分類群の系統学や進化学に関する論文が掲載される。それらの研究の中での記載であれば記載を伴う論文も掲載される。',
    },
    {
        id: 3, journalName:'Zoological Journal of the Linnean Society', journalNameAbbreviation:'Zool. J. Linn. Soc.',
        publisher: 'Oxford Academic Press', IF2021: 3.834, pureTaxonomy: '✕', myPaper:'Not yet.',
        remarks:'動物系統分類学に関する伝統ある雑誌。一般性の高い記載や系統分類学的論文は掲載される。ちなみに、私が投稿したときは査読後に分量が多すぎるから記載をSupprementlyにしてはどうかとの提案を受け取り下げた。',
    },
    {
        id: 4, journalName:'Insect Systematics and Diversity', journalNameAbbreviation:'Insect Syst. Divers.',
        publisher: 'Oxford Academic Press', IF2021: 2.841, pureTaxonomy: '◎', myPaper:'Not yet.',
        remarks:'最近新たに設立された昆虫の分類と多様性に関する雑誌。ハイインパクトなゲノム系統論文などが多いが、レビジョンなどの記載論文も掲載される。今後の動向次第では、記載論文の掲載難易度が上がる可能性もあるので、今が狙い時。',
    },
    {
        id: 5, journalName:'Invertebrate Systematics', journalNameAbbreviation:'Invertebr. Syst.',
        publisher: 'CSIRO Publishing', IF2021: 2.364, pureTaxonomy: '✕', myPaper:'Not yet.',
        remarks:'伝統ある無脊椎動物の体系学に関する雑誌。系統分類論文は掲載可能だが、ただの記載論文は掲載されない。ただし、一般的なインパクトのある進化や生態上重要な記載は載ることもある（化石の記載など）。',
    },
    {
        id: 6, journalName:'Zoological Studies', journalNameAbbreviation:'Zool. Stud.',
        publisher: 'the Biodiversity Research Center, Academia Sinica, Taiwan', IF2021: 1.91, pureTaxonomy: '△', myPaper:'submitted',
        remarks:'台湾中央研究院生物多様性研究センター発行の動物学の専門誌。記載分類も掲載されるが、一般的な知見を含んでいる必要があるので記載論文の書き方にコツがいる。',
    },
    {
        id: 7, journalName:'Journal of Hymenoptera Research', journalNameAbbreviation:'J. Hymenopt. Res.',
        publisher: 'Pensoft', IF2021: 1.76, pureTaxonomy: '◎', myPaper:'Published',
        remarks:'国際ハチ目学会の専門誌。純記載論文も掲載されるが、単種記載の場合は一般性が必要。',
    },
    {
        id: 8, journalName:'Austral Entomology', journalNameAbbreviation:'Austral Entomol.',
        publisher: 'Wiley', IF2021: 1.723, pureTaxonomy: '△', myPaper:'Published',
        remarks:'南半球の昆虫学に関する一般専門誌。記載論文も掲載されるが、一般性が求められる。',
    },
    {
        id: 9, journalName:'Neotropical Entomology', journalNameAbbreviation:'Neotrop. Entomol.',
        publisher: 'Springer', IF2021: 1.65, pureTaxonomy: '〇', myPaper:'Published',
        remarks:'雑誌名に反して実は全世界を対象とした昆虫学の一般性専門誌。純記載論文も掲載される。しかし、単種記載の場合は、一般性が必要。',
    },
    {
        id: 10, journalName:'Journal of Asia-Pacific Entomology', journalNameAbbreviation:'J. Asia Pac. Entomol.',
        publisher: 'Elsevier', IF2021: 1.58, pureTaxonomy: '△', myPaper:'Published',
        remarks:'昆虫学に関する一般学術誌。記載論文も掲載されるが、一般性が必要。',
    },
    {
        id: 11, journalName:'Biodiversity Data Journal', journalNameAbbreviation:'Biodivers. Data J.',
        publisher: 'Pensoft', IF2021: 1.54, pureTaxonomy: '◎', myPaper:'Not yet.',
        remarks:'生物多様性データに関するあらゆるデータペーパーが掲載される。分類やファウナデータもフォーカスに含まれる。データペーパーはメタ解析や総説などで引用されやすいためIFが高くてコスパはいい。',
    },
        {
        id: 12, journalName:'Zookeys', journalNameAbbreviation:'Zookeys',
        publisher: 'Pensoft', IF2021: 1.492, pureTaxonomy: '◎', myPaper:'Published',
        remarks:'言わずと知れた動物学に関するメガジャーナル。',
    },
    {
        id: 13, journalName:'European Journal of Taxonomy', journalNameAbbreviation:'Eur. J. Taxon.',
        publisher: 'European Natural History Institutes', IF2021: 1.398, pureTaxonomy: '◎', myPaper:'submitted',
        remarks:'ページ制限なし、投稿料なし、オープンアクセスと分類論文のための雑誌。',
    },
    {
        id: 14, journalName:'Entomological Science', journalNameAbbreviation:'Entomol. Sci.',
        publisher: 'Wiley', IF2021: 1.304, pureTaxonomy: '△', myPaper:'Published',
        remarks:'日本昆虫学会の英文誌。記載論文も掲載されるが、何からの一般性が必要。',
    },
    {
        id: 15, journalName:'Insect Systematics & Evolution', journalNameAbbreviation:'Insect Syst. Evol.',
        publisher: 'Brill', IF2021: 1.304, pureTaxonomy: '△', myPaper:'Published',
        remarks:'単種記載の場合は、一般性が必要。レビジョンなどは掲載可能。',
    },
    {
        id: 16, journalName:'Deutsche Entomologische Zeitschrift', journalNameAbbreviation:'Dtsch. Entomol. Z.',
        publisher: 'Pensoft', IF2021: 1.174, pureTaxonomy: '◎', myPaper:'Published',
        remarks:'伝統と格式あるドイツの昆虫学誌。Pensoftになってから紙面がカッコよくなった。最近、色々な人にお勧めしてる雑誌。',
    },
    {
        id: 17, journalName:'Zootaxa', journalNameAbbreviation:'Zootaxa',
        publisher: 'Magnolia Press', IF2021: 1.091, pureTaxonomy: '◎', myPaper:'Published',
        remarks:'言わずと知れた動物分類学のメガジャーナル。最近姉妹紙にMegataxaというモノグラフ専門誌が出来たので、まだIFはついていないがでかい論文はそっちを狙ってみるのも面白いと思う。',
    },
    {
        id: 18, journalName:'Alpine Entomology', journalNameAbbreviation:'Alp. Entomol.',
        publisher: 'Pensoft', IF2021: 1.03, pureTaxonomy: '◎', myPaper:'Not yet.',
        remarks:'全世界の山地の昆虫に関する専門誌。',
    },
    {
        id: 19, journalName:'Systematic Parasitology', journalNameAbbreviation:'Syst. Parasitol.',
        publisher: 'Springer', IF2021: 1.023, pureTaxonomy: '◎', myPaper:'Not yet.',
        remarks:'全世界の寄生性生物の分類専門誌。フォーカスに寄生性ハチ類も含むと明記されている。',
    },
    {
        id: 20, journalName:'Journal of Natural History', journalNameAbbreviation:'J. Nat. Hist.',
        publisher: 'Taylor & Francis', IF2021: 0.965, pureTaxonomy: '◎', myPaper:'Published',
        remarks:'英国自然史博物館刊行の自然史学の専門誌。',
    },
    {
        id: 21, journalName:'Acta Entomologica Musei Nationalis Prague', journalNameAbbreviation:'Acta Entomol. Mus. Natl. Pragae.',
        publisher: 'National museum, Czech Republic', IF2021: 0.883, pureTaxonomy: '◎', myPaper:'Published',
        remarks:'ページ制限なし（でかい論文は事前問い合わせ必要）、投稿料無料、オープンアクセス、無料別刷配送と至れり尽くせりな雑誌。近年、2カラムになってからさらに紙面がカッコよくなった。',
    },
    {
        id: 22, journalName:'Oriental Insect', journalNameAbbreviation:'Oriental Insect',
        publisher: 'Taylor & Francis', IF2021: 0.527, pureTaxonomy: '◎', myPaper:'Published',
        remarks:'私が投稿したときは査読者が5人もいてかなり厳しかったのを覚えている。',
    },
    {
        id: 23, journalName:'Acta Zoologica Bulgarica', journalNameAbbreviation:'Acta Zool. Bulg.',
        publisher: 'Institute of Biodiversity and Ecosystem Research, Bulgarian Academy of Sciences', IF2021: 0.448, pureTaxonomy: '◎', myPaper:'Published',
        remarks:'動物学に関する専門誌。',
    },
    {
        id: 24, journalName:'Taiwanese Journal of Entomological Studies', journalNameAbbreviation:'Taiwanese Journal of Entomological Studies',
        publisher: '台湾昆虫同好会', IF2021: 0.000, pureTaxonomy: '✕', myPaper:'Published',
        remarks:'命名規約上の措置を伴う論文は掲載不可。生態や分布に関する短報が掲載される。',
    },
    {
        id: 25, journalName:'Entomological Communications', journalNameAbbreviation:'Entomol. Commun.',
        publisher: 'ブラジル昆虫学会', IF2021: 0.000, pureTaxonomy: '✕', myPaper:'Published',
        remarks:'命名規約上の措置を伴う論文は掲載不可。短報が掲載される。',
    },
    {
        id: 26, journalName:'Japanese Journal of Systematic Entomology', journalNameAbbreviation:'Jpn. J. Syst. Entomol.',
        publisher: '日本昆虫分類学会', IF2021: 0.000, pureTaxonomy: '◎', myPaper:'Published',
        remarks:'分類や系統、ファウナなどに関する論文が掲載される。',
    },
    {
        id: 27, journalName:'Annales de la Societe entomologique de France', journalNameAbbreviation:'Ann. Soc. Entomol. Fr.',
        publisher: 'Taylor & Francis', IF2021: 1.200, pureTaxonomy: '◎', myPaper:'Not yet.',
        remarks:'昆虫学に関する国際誌。単種記載の場合は、ある程度の一般性を伴う必要あり。',
    },
    {
        id: 28, journalName:'Annals of the Entomological Society of America', journalNameAbbreviation:'Ann. Entomol. Soc. Am.',
        publisher: 'Oxford Academic Press', IF2021: 2.727, pureTaxonomy: '✕', myPaper:'Not yet.',
        remarks:'かつては分類論文も掲載されていたが分類学に関する姉妹誌ISDが出来たため記載論文は基本的に掲載されない。',
    },
    {
        id: 29, journalName:'Species Diversity', journalNameAbbreviation:'Species Divers.',
        publisher: '日本動物分類学会', IF2021: 0.000, pureTaxonomy: '◎', myPaper:'Not yet.',
        remarks:'日本動物分類学会の英文誌。',
    },
    {
        id: 30, journalName:'Zoologischer Anzeiger', journalNameAbbreviation:'Zool. Anz.',
        publisher: 'Elsevier', IF2021: 1.581, pureTaxonomy: '△', myPaper:'Not yet.',
        remarks:'動物学に関する歴史ある雑誌。系統分類論文は掲載可能。',
    },
    {
        id: 31, journalName:'Raffles Bulletin of Zoology', journalNameAbbreviation:'Raffles Bull. Zool.',
        publisher: 'Lee Kong Chian Natural History Museum, National University of Singapore', IF2021: 1.198, pureTaxonomy: '◎', myPaper:'Not yet.',
        remarks:'東南アジアを中心とした各地の動物学に関する雑誌。記載論文はレビジョンやある程度の一般性を伴う必要あり。',
    },
    {
        id: 32, journalName:'Contributions to Zoology', journalNameAbbreviation:'Contrib. Zool.',
        publisher: 'Brill', IF2021: 2.750, pureTaxonomy: '△', myPaper:'Not yet.',
        remarks:'純記載も考慮するらしいが、基本的には厳しそう。系統分類論文は沢山載っている模様。',
    },
    {
        id: 33, journalName:'Cladistics', journalNameAbbreviation:'Cladistics',
        publisher: 'Wiley', IF2021: 4.714, pureTaxonomy: '✕', myPaper:'Not yet.',
        remarks:'憧れの雑誌のひとつ。人為的な影響があり得るモデルベースの解析ではなく、再節約的に支持される結果による論文が掲載される。',
    },
    {
        id: 34, journalName:'Biosystematics and Ecology', journalNameAbbreviation:'Biosyst. Ecol.',
        publisher: 'Pensoft', IF2021: '', pureTaxonomy: '', myPaper:'',
        remarks:'',
    },
    /*
    {
        id: 35, journalName:'', journalNameAbbreviation:'',
        publisher: '', IF: '', pureTaxonomy: '', myPaper:'',
        remarks:'',
    },
    */
];