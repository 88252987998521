import './AboutMe.css';
import {Breadcrumb} from 'react-bootstrap';
import OphioneullusImg from "./src/Ophioneullus.jpg"

export default function AboutMe() {
    return (
        <>
            <Breadcrumb style={{zIndex:"9",color:"white"}}>
                <Breadcrumb.Item href="/" style={{zIndex:"9"}}>Home</Breadcrumb.Item>
                <Breadcrumb.Item active style={{zIndex:"9",color:"white"}}>About</Breadcrumb.Item>
            </Breadcrumb>

            <h1 className='page-type-name'>ABOUT</h1>

            <div className='about-me-title-area-container'>
                <img className='about-me-img' src={ "../So_Shimizu.jpg" } alt="So Shimizu"/>
                <div className='about-me-title-area'>
                    <h1 className='page-subtitle'>清水　壮</h1>
                    <h1 className='page-title'>SHIMIZU, SO</h1>
                    <br/>
                    <p>
                        寄生性ハチ類を研究している日本の昆虫学者です。<br/>
                        I'm a Japanese Entomologist, studying parasitoid wasps especially of Ichneumonoidea.
                    </p>
                </div>
            </div>

            <h2 className='section-title'>所属 Affiliation</h2>
            <ul>
                <li>
                    <b>日本学術振興会特別研究員（JSPS fellow PD）</b><br/>
                    （受入研究機関：国立研究開発法人 農業・食品産業技術総合研究機構）<br/>
                    Institute for Agro-Environmental Sciences, NARO, Kannondai 3–1–3, Tsukuba 305–8604, Japan
                </li>
            </ul>

            <h2 className='section-title'>所属学会 Academic society memberships</h2>
            <ul>
                <li>
                    <b>日本昆虫学会　若手正会員</b><br/>
                    The Entomological Society of Japan (XXXX–present)
                </li>
                <li>
                    <b>国際ハチ目学会　一般会員</b><br/>
                    International Society of Hymenopterists (XXXX–present)
                </li>
                <li>
                    <b>日本昆虫分類学会</b>
                </li>
                <li>
                    <b>日本動物学会</b>
                </li>
            </ul>

            <h2 className='section-title'>E-mail</h2>
            <ul>
                <b>Note: please change "[ at ]" to "@".</b>
                <li>parasitoidwasp.sou [ at ] gmail.com (primary)</li>
                <li>SoShimizuHym [ at ] outlook.jp (sub)</li>
            </ul>

            <h2 className='section-title'>Skills</h2>
            <ul>
                <li>
                    <b>昆虫の種同定 insect identification</b><br/>
                    専門はヒメバチ上科です。
                    しかし、普通の昆虫ならおおよその同定スキルがあります。
                </li>
                <li>
                    <b>系統解析 phylogenetic analysis</b><br/>
                    主に分子系統解析を得意としています。
                </li>
                <li>
                    <b>種定義解析 species delimitation analysis</b><br/>
                    塩基置換数（ABGDやthreshold解析など）や系統樹の樹形（PTP系やGMYCなど）に基づいた様々な種定義解析を行えます。
                </li>
                <li>
                    <b>高解像度深度合成写真撮影 High-resolution multi-focus stacking photos</b><br/>
                    論文用の図版作成の過程で習得しました。
                    昔はNikon派でD90➨D750などを使っていましたが、大学院以降はSony一筋でα 7IIや7R IIを用いています。
                    また、合成ソフトはCombine ZMやHelcon focusなども使ってきましたが、今はZelene stackerをメインに使っています。
                    最近の写真のクオリティに関しては、最近出版されている私の論文の写真をご覧ください。
                </li>
                <li>
                    <b>Adobe系ソフトによるraw画像の現像や画像編集、作図、版組などAdobe Illustrator, Photoshop, Indesign, and Lightroom</b><br/>
                    論文の図版作成の過程や博士論文作成の過程で習得しました。
                </li>
                <li>
                    <b>プログラミング programming</b><br/>
                    完全に独学で組織開発などの経験はありません。
                    書いた経験があるのは、golang, JavaScript (react), html, css, python, R, rustです。
                    遺伝子配列データを操作する生物情報学的処理などに用いる他、
                    リリースされている解析ソフトのソースコードに問題があるときに自力で対応したりも出来ます。
                    また、自分専用のタスク管理アプリや本ページのようなWebアプリ開発なども出来るのでかなり役立っています。
                </li>
            </ul>

            <h2 className='section-title'>Websites</h2>
            <ul>
                <li><a href="https://scholar.google.com/citations?user=EJ0BcgoAAAAJ&hl=en" target="_blank" rel="noreferrer">Google Scholar</a></li>
                <li><a href="https://orcid.org/0000-0002-5202-4552" target="_blank" rel="noreferrer">ORCID</a></li>
                <li><a href="https://www.researchgate.net/profile/So_Shimizu" target="_blank" rel="noreferrer">ResearchGate</a></li>
                <li><a href="https://researchmap.jp/parasitoidwasps/" target="_blank" rel="noreferrer">researchmap</a></li>
            </ul>

            <h2 className='section-title'>Education</h2>
            <ul>
                <li>
                    <b>2008/4-2011/3 高等学校</b><br/>
                    新潟県立国際情報高等学校情報科学科
                </li>
                <li>
                    <b>2011/4-2016/3 学士：理学</b><br/>
                    筑波大学生命環境学群生物学類
                </li>
                <li>
                    <b>2016/4-2018/3 修士：農学</b><br/>
                    神戸大学大学院農学研究科生命機能科学専攻 博士課程前期課程
                </li>
                <li>
                    <b>2018/4-2021/3 博士：農学</b><br/>
                    神戸大学大学院農学研究科生命機能科学専攻 博士課程後期課程
                </li>
            </ul>

            <h2 className='section-title'>Professinal experience</h2>
            <ul>
                <li>
                    <b>2018/4-2021/3</b><br/>
                    日本学術振興会 特別研究員（DC1）
                </li>
                <li>
                    <b>2019/9-2020/2</b><br/>
                    英国自然史博物館生命科学部門昆虫分野 外来研究員
                </li>
                <li>
                    <b>2021/4-2022/3</b><br/>
                    神戸大学大学院農学研究科 学術研究員（助教相当）
                </li>
                <li>
                    <b>2022/4-2023/3</b><br/>
                    神戸大学大学院農学研究科 博士研究員
                </li>
                <li>
                    <b>2022/4-2023/3</b><br/>
                    農業・食品産業技術総合研究機構植物防疫研究部門 契約職員
                </li>
                <li>
                    <b>2023/4-2026/3</b><br/>
                    日本学術振興会 特別研究員（PD）
                </li>
            </ul>

            <h2 className='section-title'>Academic contribution</h2>
            <ul>
                <li>
                    <b>2024/4-</b><br />
                    ZooKeys, subject editor
                </li>
                <li>
                    <b>2024/4-</b><br />
                    Biodiversity Data Journal, subject editor
                </li>
            </ul>

            <h2 className='section-title'>PC環境</h2>
            <ul>
                ローカル環境における解析やコーディングは以下の環境下で行っています。
                <li>
                    <b>デスクトップ</b><br/>
                    OS: Windows11 + CPU: Core-i9 12900K (16コア/24スレッド) + 物理メモリ: 64GB
                </li>
                <li>
                    <b>ラップトップ１</b><br/>
                    OS: Windows 11 + CPU: Core i7-1360P (12コア/16スレッド) + 物理メモリー: 32GB
                </li>
                <li>
                    <b>ラップトップ２</b><br/>
                    OS: Windows10 + CPU: Core-i7 8565U (4コア/8スレッド) + 物理メモリ: 32GB
                </li>
            </ul>

            <h2 className='section-title'>趣味 Hobbies</h2>
            <ul>
                <li>
                    <b>ハチの標本作成</b><br/>
                    疲れている時や眠気に襲われている時はついつい標本を作ってしまいます。
                    最近は、そうして作った標本が溜まり過ぎているので、同定と整理を頑張らなくてはいけないなと思っています。
                </li>
                <li>
                    <b>図鑑を眺めること</b><br/>
                    最近また小さかったころのように一般向けの図鑑を眺めています。
                    最近の一般向けの図鑑はコアなものや優しく見せて実は超最先端でハイレベルな内容を含んでいるものなどもありとても勉強になります。
                    また、図鑑執筆者の巧みな言葉使いなどもとても勉強になります。
                    論文執筆や申請書執筆などにおいて、小難しい専門的な内容をやわらかい表現で正確かつ明確に伝えるために必要な表現を広げるのにとても役立っています。
                </li>
                <li>
                    <b>動画鑑賞</b><br/>
                    昔から自転車やスキーなどのアクロバットやストリート系のものが好きなので、
                    Xゲーム（主にスキーハーフパイプ・スロープスタイル、BMX）の動画やワールドカップ（アルペンスキー技術系種目）、WRCの動画をよく見ています。
                    また、最近は海外のPranksなどのいたずら系動画やリールを見るのも好きです。
                </li>
                <li>
                    <b>寿司を食べることとお酒を飲むこと</b><br/>
                    とにかく寿司が好きです。特にブリとアジが好物です。最近、本マグロの脂の美味しさに目覚めてしまいましたが、値段や個体数を考えるとなかなか食べれなくて悲しいです。
                    お酒もかなり好きです。日本酒一升飲んでも大丈夫なので比較的強いほうかと思います。
                </li>
            </ul>

        </>
    );
};