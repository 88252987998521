import './CSS/App.css';
import './CSS/root.css';
import './CSS/font.css';
import './CSS/button.css';
import './CSS/code.css';
//import './CSS/modal.css';
import React from 'react';
import { Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';

import HeadNavBar from './components/HeadNavBar/HeadNavBar';
import Home from './components/Home/Home'
import Publications from './components/Achievements/Publications/Publications.jsx'
import TargetJournalList from './components/Note/TargetJournalList/TargetJournalList'
import UsefulLinks from './components/Note/UsefulLinks/UsefulLinks';
import TopFade from './components/fade/TopFade';
import BottomFade from './components/fade/BottomFade';
import Contact from './components/Contact/Contact';
import NewTaxa from './components/Achievements/NewTaxa/NewTaxa';
import Footer from './components/Footer/Footer';
import Presentations from './components/Achievements/Presentations/Presentations';
import Grants from './components/Achievements/Grants/Grants';
import TipsFigureEditingMethods from './components/Note/TipsFigureEditingMethods/TipsFigureEditingMethods';
import AboutParasitoidWasps from './components/About/AboutParasitoidWasps/AboutParasitoidWasps';
import MyResearchTheme from './components/About/MyResearchTheme/MyResearchTheme';
import AboutMe from './components/About/AboutMe/AboutMe';
import TipsSpeciesDelimitation from './components/Note/TipsSpeciesDelimitation/TipsSpeciesDelimitation';
import MemoSoftwareList from './components/Note/MemoSoftwareList/MemoSoftwareList';
import TipsMrBayes from './components/Note/TipsMrBayes/TipsMrBayes';
import PythonClass from './components/Note/PythonClass/PythonClass';
//import Python_openai_api_introduction from './components/Note/Python_openai_api_introduction/Python_openai_api_introduction';
import PythonOpenaiApiIntroduction from './components/Note/Python_openai_api_introduction/Python_openai_api_introduction';
import SangerSeqProtocol from './components/Note/Sanger_seq_protocol/Sanger_seq_protocol';
import DNAseqFormatConverter from './components/Tools/DNAseqFormatConverter/DNAseqFormatConverter';
import { useTheme } from "./ThemeProvider";

export default function App() {

  const { bgColor, fontColor } = useTheme(); // Use the bgColor from the context

  return (
    <div className="App" style={{ backgroundColor: bgColor, color:fontColor }}>
      <HeadNavBar />
      <TopFade />
      <div className='react-root'>
      <Helmet>
        <script type='text/javascript' src='https://d1bxh8uas1mnw7.cloudfront.net/assets/embed.js'/>


      </Helmet>
      <Routes>
        <Route path={ "/" } element={<Home />}/>

        <Route path={ "/about-me" } element={<AboutMe />}/>
        <Route path={ "/about-research-theme" } element={<MyResearchTheme />}/>
        <Route path={ "/about-parasitoid-wasps" } element={<AboutParasitoidWasps />}/>

        <Route path={ "/publications" } element={<Publications />} />
        <Route path={ "/presentations" } element={<Presentations />}/>
        <Route path={ "/new-taxa" } element={<NewTaxa />}/>
        <Route path={ "/grants" } element={<Grants />}/>

        <Route path={ "/target-journal-list" } element={<TargetJournalList />}/>
        <Route path={ "/software-list" } element={<MemoSoftwareList />}/>
        <Route path={ "/useful-links" } element={<UsefulLinks />}/>

        <Route path={ "/figure-editing-technique" } element={<TipsFigureEditingMethods />}/>
        <Route path={ "/molecular-species-delimitation" } element={<TipsSpeciesDelimitation />}/>
        <Route path={ "/mr-bayes" } element={<TipsMrBayes />}/>

        <Route path={ "/python-class-methods" } element={<PythonClass />}/>

        <Route path={ "/python_openai_api_introduction" } element={<PythonOpenaiApiIntroduction />}/>
        <Route path={ "/sanger_seqs_protocol" } element={<SangerSeqProtocol/>}/>


        <Route path={ "/dna_seq_format_converter" } element={ <DNAseqFormatConverter /> }/>
        


        <Route path={ "/contact" } element={<Contact />} state={{ bgColor:bgColor }}/>
      </Routes>
      </div>
      <Footer />
      <BottomFade />
    </div>
  );
};