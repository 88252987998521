import React from 'react';
import'./css_Footer.css';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

export default function Footer() {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };

    return (
        <footer>
            <div className='footer-inner-wrap'>
            <h5 style={{fontWeight:"bold"}}>管理人</h5>
            清水　壮 So Shimizu<br/>
            <br/>
            <h5 style={{fontWeight:"bold"}}>CREATIVE COMMONS ATTRIBUTION</h5>
            <a
                rel="license"
                href="http://creativecommons.org/licenses/by-nc-nd/4.0/"><img className='cc-licence-button' alt="Creative Commons License" src="https://i.creativecommons.org/l/by-nc-nd/4.0/88x31.png"
                style={{
                    width:"150px",
                    marginBottom:"5px",
                    marginTop:"0px",
                    marginLeft:"0px"
                }}
            /></a>
            <p>
                本ページに掲載されている全ての内容およびソースコードはCC BY, NC, NDにより保護されているため、
                管理人に許可を得ずコピーや改変、商用利用等をすることを禁じています。<br/>
                This work is licensed under a Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International License. 
                For more details, please see link from below image button.
            </p>
            </div>



            <br/>




        </footer>
    );
};