import './NewTaxa.css';
import { DataGrid } from '@mui/x-data-grid';
import {Breadcrumb} from 'react-bootstrap';
import NewTaxaIcon from './BinaTop.jpg';
import {useTheme} from "../../../ThemeProvider";

export default function NewTaxa() {
    const { fontColor } = useTheme();

    return (
        <>
        <Breadcrumb style={{zIndex:"9",color:"white"}}>
            <Breadcrumb.Item href="/" style={{zIndex:"9"}}>Home</Breadcrumb.Item>
            <Breadcrumb.Item active style={{zIndex:"9",color:"white"}}>命名した新分類群 New taxa described by So Shimizu</Breadcrumb.Item>
        </Breadcrumb>

        <h1 className='page-type-name'>NEW TAXA</h1>

<div className='about-me-title-area-container'>
    <img className='about-me-img' src={ NewTaxaIcon } alt="So Shimizu" style={{backgroundColor:"white"}}/>
    <div className='about-me-title-area'>
        <h1 className='page-subtitle'>命名した新分類群</h1>
        <h1 className='page-title'>NEW TAXA</h1>
        <br/>
        <p>
        <b>～Current status～</b><br/>
                    New taxa：43<br/>
                    New genus：1<br/>
                    New species：42
        </p>
    </div>
</div>


        <p>
            私がこれまでに命名した新分類群の分類と学名に関してテーブル形式でリスト化しています。
            分類階級ごとにテーブルを分けています。
            また、各テーブルのフィールド名部分をクリックするとそのカラムを並べ替えすることが出来ます。
            さらに、フィールド名の右端にあるボタンを展開すると、並び替えの方法の選択や表示検索フィルター、表示するカラムの設定なども可能です。
            尚、IDは主キーとして記載順に振ってあるだけなので深い意味はありません。
        </p>

        <div style={{ height: '660px', width: '100%' }}>
            <h2 className='section-title-sub'>新属　New genus</h2>
            <DataGrid
                style={{
                    color:fontColor,
                    fontSize:'18px',
                }}
                rows={rows_new_genus}
                columns={columns_new_genus}
                pageSize={10}
                rowsPerPageOptions={[10]}
            />
        </div>

        <br/>
        <br/>
        <br/>

        <div style={{ height: '660px', width: '100%' }}>
            <h2 className='section-title-sub'>新種　New species</h2>
            <DataGrid
                style={{
                    color:'white',
                    fontSize:'18px',
                }}
                rows={rows_new_species}
                columns={columns_new_species}
                pageSize={10}
                rowsPerPageOptions={[10]}
            />
        </div>

        <br/>
        <br/>
        <br/>

        <div style={{ height: '660px', width: '100%' }}>
            <h2 className='section-title-sub'>おまけ：私の名前が付いた分類群 Taxa named for me</h2>
            <DataGrid
                style={{
                    color:'white',
                    fontSize:'18px',
                }}
                rows={rows_named_for_SS}
                columns={columns_named_for_SS}
                pageSize={10}
                rowsPerPageOptions={[10]}
            />
        </div>

        </>
    );
};

const columns_named_for_SS = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'subfamily', headerName: 'Subfamily', width: 180 },
    { field: 'genus', headerName: 'Genus', width: 150 },
    { field: 'species', headerName: 'Species', width: 150 },
    { field: 'author', headerName: 'Author', width: 230 },
    { field: 'year', headerName: 'Year & Page', width: 130 },
    { field: 'status', headerName: 'Status', width: 100 },
];
const rows_named_for_SS = [
    { id: 1, subfamily: 'Banchinae', genus: 'Glypta', species: 'shimizui', author:'Watanabe', year:'2017', status:'valid' },
    { id: 2, subfamily: 'Phygadeuontinae', genus: 'Tropistes', species: 'shimizui', author:'Watanabe', year:'2021', status:'valid' },
    { id: 3, subfamily: 'Sisyrostolinae', genus: 'Peucobius', species: 'shimizui', author:'Khalaim', year:'2023', status:'valid' },
];

const columns_new_genus = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'order', headerName: 'Order', width: 90 },
    { field: 'family', headerName: 'Family', width: 160 },
    { field: 'subfamily', headerName: 'Subfamily', width: 180 },
    { field: 'genus', headerName: 'Genus', width: 150 },
    { field: 'author', headerName: 'Author', width: 230 },
    { field: 'year', headerName: 'Year & Page', width: 130 },
    { field: 'status', headerName: 'Status', width: 100 },
];
const rows_new_genus = [
    { id: 1, date:'2020', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Nesomesochorinae', genus: 'Bina', author:'Shimizu & Alvarado', year:'2020', typeSpecies:'Bina huayrurae Shimizu & Alvarado, 2020', status:'valid' },
];

const columns_new_species = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'order', headerName: 'Order', width: 90 },
    { field: 'family', headerName: 'Family', width: 160 },
    { field: 'subfamily', headerName: 'Subfamily', width: 180 },
    { field: 'genus', headerName: 'Genus', width: 150 },
    { field: 'species', headerName: 'Species', width: 150 },
    { field: 'author', headerName: 'Author', width: 230 },
    { field: 'year', headerName: 'Year & Page', width: 130 },
    { field: 'status', headerName: 'Status', width: 100 },
];

const rows_new_species = [
    { id: 1, date:'2015', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Leptophion', species: 'parvus', author:'Shimizu & Watanabe', year:'2015', status:'valid' },
    { id: 2, date:'2015', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Leptophion', species: 'septentrionis', author:'Shimizu & Watanabe', year:'2015', status:'valid' },
    { id: 3, date:'2016', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Leptophion', species: 'giganteus', author:'Shimizu & Watanabe', year:'2016', status:'valid' },
    { id: 4, date:'2017/6/30', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'kikuchii', author:'Shimizu', year:'2017', status:'valid' },
    { id: 5, date:'2017/8/7', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ctenopelmatinae', genus: 'Seleucus', species: 'taiwanus', author:'Shimizu', year:'2017', status:'valid' },
    { id: 6, date:'2017/9/26', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Stauropoctonus', species: 'aurantiacus', author:'Shimizu & Lima', year:'2017', status:'valid' },
    { id: 7, date:'2017/12/12', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Anomaloninae', genus: 'Therion', species: 'carinatum', author:'Shimizu & Bennett', year:'2017', status:'valid' },
    { id: 8, date:'2017/12/12', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Anomaloninae', genus: 'Therion', species: 'nigrigasterum', author:'Shimizu, Bennett & Ito', year:'2017', status:'valid' },
    { id: 9, date:'2018/3', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Tryphoninae', genus: 'Phytodietus', species: 'clavotibialis', author:'Shimizu & Konishi', year:'2018', status:'valid' },
    { id: 10, date:'2018/6/4', order:'Hym.', family: 'Braconidae', subfamily: 'Microgastrinae', genus: 'Dolichogenidea', species: 'maetoi', author:'Fernandez-Triana & Shimizu', year:'2018', status:'valid' },
    { id: 11, date:'2018/7/6', order:'Hym.', family: 'Braconidae', subfamily: 'Microgastrinae', genus: 'Cotesia', species: 'testacea', author:'Fujie, Shimizu & Fernandez-Triana', year:'2018', status:'valid' },
    { id: 12, date:'2018/7/8', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Lycorininae', genus: 'Lycorina', species: 'longicauda', author:'Shimizu', year:'2018', status:'valid' },
    { id: 13, date:'2020/5/11', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'alleni', author:'Shimizu', year:'2020', status:'valid' },
    { id: 14, date:'2020/5/11', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'kakanicus', author:'Shimizu', year:'2020', status:'valid' },
    { id: 15, date:'2020/5/11', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'nepalensis', author:'Shimizu', year:'2020', status:'valid' },
    { id: 16, date:'2020/5/11', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'nikami', author:'Shimizu', year:'2020', status:'valid' },
    { id: 17, date:'2020/5/11', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'phulchokiensis', author:'Shimizu', year:'2020', status:'valid' },
    { id: 18, date:'2020/5/11', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'tangi', author:'Shimizu', year:'2020', status:'valid' },
    { id: 19, date:'2020/8/13', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Dicamptus', species: 'curvus', author:'Pham, Matsumoto & Shimizu', year:'2020', status:'valid' },
    { id: 20, date:'2020/10', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Nesomesochorinae', genus: 'Bina', species: 'huayrurae', author:'Shimizu & Alvarado', year:'2020', status:'valid' },
    { id: 21, date:'2020/10', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Nesomesochorinae', genus: 'Bina', species: 'nigra', author:'Shimizu & Alvarado', year:'2020', status:'valid' },
    { id: 22, date:'2020/11/10', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'acutus', author:'Shimizu', year:'2020', status:'valid' },
    { id: 23, date:'2020/11/10', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'kunigamiensis', author:'Shimizu', year:'2020', status:'valid' },
    { id: 24, date:'2020/11/10', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'limnophilus', author:'Shimizu', year:'2020', status:'valid' },
    { id: 25, date:'2020/11/10', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'matsumurai', author:'Shimizu', year:'2020', status:'valid' },
    { id: 26, date:'2020/11/10', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'pseudopuncticulatus', author:'Shimizu', year:'2020', status:'valid' },
    { id: 27, date:'2020/11/10', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'sharkeyi', author:'Shimizu', year:'2020', status:'valid' },
    { id: 28, date:'2020/11/10', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'takakuwai', author:'Shimizu', year:'2020', status:'valid' },
    { id: 29, date:'2020/11/10', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'unctus', author:'Shimizu', year:'2020', status:'valid' },
    { id: 30, date:'2021/10/29', order:'Hym.', family: 'Braconidae', subfamily: 'Euphorinae', genus: 'Meteorus', species: 'stellatus', author:'Fujie, Shimizu & Maeto', year:'2021', status:'valid' },
    { id: 31, date:'2022/6/20', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Stauropoctonus', species: 'inaequalis', author:'Pham, Matsumoto, Cao & Shimizu', year:'2022', status:'valid' },
    { id: 32, date:'2023/5/26', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Ophion', species: 'kobensis', author:'Shimizu', year:'2023', status:'valid' },
    { id: 33, date:'2023/6/12', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'aequiscleritalis', author:'Pham, Pham, Matsumoto, Shimizu & Broad', year:'2023', status:'valid' },
    { id: 34, date:'2023/6/12', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'bulbipennis', author:'Pham, Pham, Matsumoto, Shimizu & Broad', year:'2023', status:'valid' },
    { id: 35, date:'2023/6/12', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'centraliscleritiger', author:'Pham, Pham, Matsumoto, Shimizu & Broad', year:'2023', status:'valid' },
    { id: 36, date:'2023/6/12', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'circuliscleritalis', author:'Pham, Pham, Matsumoto, Shimizu & Broad', year:'2023', status:'valid' },
    { id: 37, date:'2023/6/12', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'gialaiensis', author:'Pham, Pham, Matsumoto, Shimizu & Broad', year:'2023', status:'valid' },
    { id: 38, date:'2023/6/12', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'hiepi', author:'Pham, Pham, Matsumoto, Shimizu & Broad', year:'2023', status:'valid' },
    { id: 39, date:'2023/6/12', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'melanothoracicus', author:'Pham, Pham, Matsumoto, Shimizu & Broad', year:'2023', status:'valid' },
    { id: 40, date:'2023/6/12', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'nigristernalis', author:'Pham, Pham, Matsumoto, Shimizu & Broad', year:'2023', status:'valid' },
    { id: 41, date:'2023/6/12', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'trui', author:'Pham, Pham, Matsumoto, Shimizu & Broad', year:'2023', status:'valid' },
    { id: 42, date:'2023/6/12', order:'Hym.', family: 'Ichneumonidae', subfamily: 'Ophioninae', genus: 'Enicospilus', species: 'tuani', author:'Pham, Pham, Matsumoto, Shimizu & Broad', year:'2023', status:'valid' },


    //    { id: 24, date:'', order:'Hym.', family: 'Ichneumonidae', subfamily: '', genus: '', species: '', author:'', year:'', status:'valid' },
];



