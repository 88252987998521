export default function BottomFade() {
    return(
        <div style={{
            "width":"100vw",
            "min-width": "460px",
            "height":"90px",
            "position":"fixed",
            "bottom":"0",
            "display":"flex",
            "background":"linear-gradient(transparent, rgb(152, 89, 0) 60%)",
            "opacity":"0.8",
            "z-index":"9"
        }} />
    )
}