import { DataGrid } from '@mui/x-data-grid';
import {Breadcrumb} from 'react-bootstrap';
import GrantsIcon from './GrantsIcon.png';
import {useTheme} from "../../../ThemeProvider";

export default function Grants() {
    const { fontColor } = useTheme();

    return (
        <>
        <Breadcrumb style={{zIndex:"9",color:"white"}}>
            <Breadcrumb.Item href="/" style={{zIndex:"9"}}>Home</Breadcrumb.Item>
            <Breadcrumb.Item active style={{zIndex:"9",color:"white"}}>研究資金 Grants</Breadcrumb.Item>
        </Breadcrumb>

        <h1 className='page-type-name'>GRANTS & FELLOWSHIPS</h1>

<div className='about-me-title-area-container'>
    <img className='about-me-img' src={ GrantsIcon } alt="So Shimizu" style={{backgroundColor:"white"}}/>
    <div className='about-me-title-area'>
        <h1 className='page-subtitle'>獲得した競争的資金・助成</h1>
        <h1 className='page-title'>Grants & Fellowships</h1>
        <br/>
        <p>
        <b>～Current status～</b><br/>
        Total: 4
        </p>
    </div>
</div>

        <p>
            テーブル形式でリスト化しています。
            テーブルのフィールド名部分をクリックするとそのカラムを並べ替えすることが出来ます。
            さらに、フィールド名の右端にあるボタンを展開すると、並び替えの方法の選択や表示検索フィルター、表示するカラムの設定なども可能です。
            尚、IDは主キーとして発表順に振ってあるだけなので深い意味はありません。
        </p>

        <div style={{ height: '660px', width: '100%' }}>
            <h2 className='section-title-sub'>リスト List</h2>
            <DataGrid
                style={{
                    color:fontColor,
                    fontSize:'18px',
                }}
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
            />
        </div>

        <br/>
        <br/>
        <br/>

        </>
    );
};

const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'correspondence', headerName: 'Correspondence', width: 180 },
    { field: 'title', headerName: 'Title', width: 1100 },
    { field: 'funder', headerName: 'Funder', width: 590 },
    { field: 'span', headerName: 'Span', width: 200 },
    { field: 'instirution', headerName: 'Institution', width: 270 },
    { field: 'type', headerName: 'Type', width: 80 },
    { field: 'amount', headerName: 'Amount', width: 120 },
    { field: 'grantNumber', headerName: 'Grant number', width: 150 },
    { field: 'Remarks', headerName: 'Remarks', width: 180 },
];

const rows = [
    {
        id: 1, correspondence:'清水壮',
        title: 'ハチ目最原始的なナギナタハバチの寄生蜂Idiogrammatini族（ハチ目: ヒメバチ科: ハバチヤドリヒメバチ亜科）の系統分類学的研究',
        funder: '藤原ナチュラルヒストリー振興財団 第25回学術研究助成',
        span: '2017/4-2018/3', instirution: null,
        type:'grant', amount:null,
        grantNumber: null, Remarks:null,
    },
    {
        id: 2, correspondence:'清水壮',
        title: 'アメバチ亜科寄生蜂の熱帯地域における夜間適応と種多様性の系統進化学的解明',
        funder: '日本学術振興会特別研究員研究奨励費DC1',
        span: '2018/4-2021/3', instirution: '神戸大学大学院農学研究科',
        type:'fellow', amount:null,
        grantNumber: '18J20333', Remarks:null,
    },
    {
        id: 3, correspondence:'清水壮',
        title: 'Species diversity of the subfamily Ophioninae Shuckard (Hymenoptera, Ichneumonidae)',
        funder: '日本学術振興会若手研究者海外挑戦プログラム',
        span: '2019/9/1-2020/2/27', instirution: '英国自然史博物館',
        type:'fellow', amount:null,
        grantNumber:null, Remarks:null,
    },
    {
        id: 4, correspondence:'清水壮',
        title: '害虫の重要天敵「ヒメバチ科」の時間的ニッチに着目した種多様性形成過程の解明',
        funder: '日本学術振興会特別研究員研究奨励費PD',
        span: '2023/04-2026/03', instirution: '農業・食品産業技術総合研究機構',
        type:'fellow', amount:'未定',
        grantNumber: '', Remarks:'',
    },
];



