import {Breadcrumb} from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import Icon from './icon.png';
import {useTheme} from "../../../ThemeProvider";

function TitleArea() {
    return (
        <>
        <h1 className='page-type-name'>MEMO</h1>
        <div className='about-me-title-area-container'>
            <img className='about-me-img' src={ Icon } alt="So Shimizu" style={{backgroundColor:"white"}}/>
            <div className='about-me-title-area'>
                <h1 className='page-subtitle'>ソフトウェアリスト</h1>
                <h1 className='page-title'>Software list</h1>
                <br/>
                <p>
                    私がよく使っていたり、今後使う可能性がある分類学や系統学、進化学に関連するソフトウェアをリストしています。
                </p>
            </div>
        </div>
        </>
    )
}

export default function MemoSoftwareList() {
    const { fontColor } = useTheme();

    return (
        <>
        <Breadcrumb style={{zIndex:"9",color:"white"}}>
            <Breadcrumb.Item href="/" style={{zIndex:"9"}}>Home</Breadcrumb.Item>
            <Breadcrumb.Item active style={{zIndex:"9",color:"white"}}>ソフトウェアリスト Software list</Breadcrumb.Item>
        </Breadcrumb>

        {<TitleArea />}

        <p>
            テーブル形式でリスト化しています。
            各テーブルのフィールド名部分をクリックするとそのカラムを並べ替えすることが出来ます。
            さらに、フィールド名の右端にあるボタンを展開すると、並び替えの方法の選択や表示検索フィルター、表示するカラムの設定なども可能です。
            尚、IDは主キーとして記入順に振ってあるだけなので深い意味はありません。
        </p>

        <div style={{ height: '920px', width: '100%' }}>
            <h2 className='section-title-sub'>雑誌リスト Journal list</h2>
            <DataGrid
                style={{
                    color:fontColor,
                    fontSize:'18px',
                }}
                rows={rows}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[15]}
            />
        </div>
        </>
    )
}

const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Name', width: 400 },
    { field: 'manufacturer', headerName: 'Manufacturer', width: 200 },
    { field: 'useTo', headerName: 'Use to', width: 250 },
    { field: 'type', headerName: 'type', width: 200 },
    { field: 'cost', headerName: 'Cost', width: 170 },
    { field: 'remarks', headerName: 'Remarks', width: 1000 },
];
const rows = [
    {
        id: 1, name:'RazorSQL', manufacturer:'Richardson Software, LLC', useTo: 'Database management', type: 'Download to local', cost: '$99.95 per user',
        remarks:'様々なDBMSを感覚的に操作出来るので便利。価格も良心的。特に、DynamoDBのデータを一括で登録する際に助かっている。; https://razorsql.com/index.html',
    },
    {
        id: 2, name:'ALTER WEB SERVER', manufacturer:'', useTo: 'Sequence analysis', type: "web app", cost: 'free',
        remarks:'アラインメントファイルの形式を変換するオンラインサービスですが、その過程で同一のハプロタイプの除去などを行うことが出来ます。種定義や種系統解析用のデータセット用意の際に非常に便利です。',
    },
    {
        id: 3, name:'BEAST', manufacturer:'',
        useTo: 'phylogeny; genetics', type: null, cost: '',
        remarks:'',
    },
    {
        id: 4, name:'PartitionFinder2', manufacturer:'',
        useTo: 'select optimal substitution models', type: null, cost: '',
        remarks:'',
    },
    {
        id: 5, name:'trimal', manufacturer:'',
        useTo: '', type: null, cost: '',
        remarks:'',
    },
    {
        id: 6, name:'RAxML', manufacturer:'',
        useTo: '', type: null, cost: '',
        remarks:'',
    },
    {
        id: 7, name:'ape', manufacturer:'',
        useTo: '', type: 'R package', cost: '',
        remarks:'',
    },
    {
        id: 8, name:'phytools', manufacturer:'',
        useTo: '', type: 'R package', cost: '',
        remarks:'',
    },
    {
        id: 9, name:'MAFFT', manufacturer:'',
        useTo: '', type: null, cost: '',
        remarks:'',
    },
    {
        id: 10, name:'MrBayes', manufacturer:'',
        useTo: '', type: null, cost: '',
        remarks:'',
    },
    {
        id: 11, name:'FigTree', manufacturer:'',
        useTo: 'edit tree', type: null, cost: '',
        remarks:'',
    },
    {
        id: 12, name:'MCMCTREE', manufacturer:'',
        useTo: '', type: null, cost: '',
        remarks:'',
    },
    {
        id: 13, name:'PHYLUCE', manufacturer:'',
        useTo: '', type: null, cost: '',
        remarks:'',
    },
    {
        id: 14, name:'Aminosan', manufacturer:'', useTo: '', type: null, cost: '',
        remarks:'',
    },
    {
        id: 15, name:'EMBOSS', manufacturer:'', useTo: '', type: null, cost: '',
        remarks:'',
    },
    {
        id: 16, name:'jMODELTEST', manufacturer:'', useTo: '', type: null, cost: '',
        remarks:'進化モデルの選択を行ってくれます。低速ですが使い方は分かり易いです。（論文DOI：https://doi.org/10.1093/molbev/msn083）',
    },
    {
        id: 17, name:'Kakusan4', manufacturer:'', useTo: '', type: null, cost: '',
        remarks:'',
    },
    {
        id: 18, name:'MEGA', manufacturer:'', useTo: '', type: null, cost: '',
        remarks:'多重配列アラインメントから系統解析や分岐年代推定などまで行える万能ソフトです。2020年10月現在での最新版はMEGA Xです。',
    },
    {
        id: 19, name:'ModelTest-NG', manufacturer:'', useTo: '', type: null, cost: '',
        remarks:'進化モデルの選択を行ってくれます。比較的高速です。（論文DOI：https://doi.org/10.1093/molbev/msz189）',
    },
    {
        id: 20, name:'paml', manufacturer:'', useTo: '', type: null, cost: '',
        remarks:'',
    },
    {
        id: 21, name:'PROMALS3D', manufacturer:'', useTo: '', type: null, cost: '',
        remarks:'',
    },
    {
        id: 22, name:'TranslatorX', manufacturer:'', useTo: '', type: null, cost: '',
        remarks:'オンラインで多重配列アラインメントを行ってくれます。MEGAでもMUSCLEやclustalwなどを用いてコドンを考慮したアラインメントが可能ですが、TranslatorXの方がMAFFTなども使用できる点や解析に適さない領域をコドンを考慮しながら削除してくれる点、結果の視認性の良さなどで個人的に優れているように思えます。',
    },
    {
        id: 23, name:'Indenticator', manufacturer:'', useTo: 'Visual Studio code', type: null, cost: '',
        remarks:'VSコードの開発支援拡張。作業中の階層が分かりやすくなって便利。',
    },
];
