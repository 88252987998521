import './Home.css';
import NewsData from "./News.json";
import React from 'react';
import { Button, Breadcrumb } from 'react-bootstrap';
import {useTheme} from "../../ThemeProvider";

export default function Home() {
    const { bgColor, fontColor } = useTheme(); // Use the bgColor from the context

    return (
        <>

            <Breadcrumb style={{zIndex:"9",color:"white"}}>
                <Breadcrumb.Item active style={{zIndex:"9",color:"white"}}>Home</Breadcrumb.Item>
            </Breadcrumb>

            {/*<HomeIntroImgs />*/}

                <div className='CommentForVisitor'>

                    <h2>News</h2>
                    <div className="home-information">
                        <HomeNewInformation/>
                    </div>
                    <br/>

                    <h2>Welcome to my webpage!</h2>
                    <p>
                        清水壮のウェブサイトをご覧いただきありがとうございます。<br/>
                        私は地球上で最も多様性に富み美しい動物のひとつである昆虫の多様性と進化の歴史の全貌を解明すべく、
                        <span className='txt-underline'>系統分類学・系統進化学</span>を専門に研究に取り組んでいます。<br/>
                        昆虫の中でも寄生性ハチ類に注目し、その研究を行っています。<br/>
                        ※PCからの閲覧を推奨いたします。<br/>
                        <div className='home-buttons-wrap'> 
                            <Button className='button-main-txt' href={"/publications"} style={{backgroundColor:bgColor, color:fontColor}}>学術論文</Button>
                            <Button className='button-main-txt' href={"/new-taxa"} style={{backgroundColor:bgColor, color: fontColor}}>新分類群</Button>
                            <Button className='button-main-txt' href={"/contact"} style={{backgroundColor:bgColor, color:fontColor}}>連絡先</Button>
                        </div>
                        <br/>
                        Thank you for visiting So Shimizu's website.<br/>
                        I'm a Japanese researcher and strongly interested in the true biodiversity and evolutionary history of the most diverse and beautiful insect world on Earth.<br/>
                        In particular, I love parasitoid wasps among insects and am studying them.<br/>
                        You can find details of my research, achievements, recent activities, tips, etc.<br/>
                        Note: I recommend viewing from a PC.<br/>
                        <div className='home-buttons-wrap'>
                            <Button className='button-main-txt' href={"/publications"}style={{backgroundColor:bgColor,color:fontColor}}>Publications</Button>
                            <Button className='button-main-txt' href={"/new-taxa"} style={{backgroundColor:bgColor,color:fontColor}}>New taxa</Button>
                            <Button className='button-main-txt' href={"/contact"} style={{backgroundColor:bgColor,color:fontColor}}>Contact</Button>
                        </div>
                        <br/>
                    </p>
                    <h2>Ichneumonoidea Web Database</h2>
                    <p>
                        世界のヒメバチ上科の学名をまとめたデータベースの運用も行っています。<br/>
                        現在、データの登録とユーザーインターフェースなど諸々作成途中ではありますが、随時更新を進めています。<br/>
                        <div className='home-buttons-wrap'>
                            <Button className='button-main-txt' href={"https://world-ichneumonoidea.app/"} target='_blank' rel="noreferrer" style={{backgroundColor:bgColor,color:fontColor}}>Ichneumonoidea Web Database (primary domain)</Button>
                            <Button className='button-main-txt' href={"https://ichneumonoidea-database.web.app/"} target='_blank' rel="noreferrer" style={{backgroundColor:bgColor,color:fontColor}}>Ichneumonoidea Web Database (secondary domain)</Button>
                        </div>
                    </p>

                    <p>
                        キーワード key words
                        <ul>
                            <li>昆虫学 entomology</li>
                            <li>分類学 taxonomy</li>
                            <li>系統学 phylogeny</li>
                            <li>進化学 evolutionary biology</li>
                            <li>生物多様性 biodiversity</li>
                            <li>DNAバーコーディング DNA barcoding</li>
                            <li>昆虫 insect</li>
                            <li>寄生性ハチ類 parastica, parasitoid wasps</li>
                            <li>ヒメバチ Ichneumonidae, Darwin wasps</li>
                            <li>コマユバチ Braconidae</li>
                        </ul>
                    </p>
                </div>
        </>
    )
}

export function HomeNewInformation() {
    const completedNews = NewsData.filter(item => item.status === "complete")
                                    .sort((a, b) => new Date(b.date) - new Date(a.date));
    const upcomingNews = NewsData.filter(item => item.status !== "complete")
                                  .sort((a, b) => new Date(b.date) - new Date(a.date));

    const renderNewsItems = (newsItems) => (
        <ul>
            {newsItems.map((item, index) => (
                <li key={index}>
                    {item.date} <b>【{item.type}】</b>
                    <span dangerouslySetInnerHTML={{__html: item.msg}}></span> {/* Use dangerouslySetInnerHTML to render HTML inside msg */}
                </li>
            ))}
        </ul>
    );

    return (
        <>
            {upcomingNews.length > 0 && (
                <>
                    <h3>予定・近況</h3>
                    {renderNewsItems(upcomingNews)}
                </>
            )}
            {completedNews.length > 0 && (
                <>
                    <h3>完了</h3>
                    {renderNewsItems(completedNews)}
                </>
            )}
        </>
    );
}

/*
export function HomeNewInformation() {
    return (
        <>
            <b>予定・近況</b>
            <ul>
                <li>[近況 2024/2/1] 学振PDで行うUCEs解析用の試薬が徐々に揃ってきました。来年度からの本格的な実験の開始が待ち遠しいです。</li>
            </ul>
            <b>完了</b>
            <ul>
                <li>[論文掲載 2024/1/19] <i>Lepidoptera Science</i>に阪上洸多博士との共著論文が掲載されました。</li>
                <li>[論文掲載 2023/12/25] <i>昆蟲（ニューシリーズ）</i>に大松勇司さん、河合諒人さん、藏滿司夢博士との共著論文が掲載されました。</li>
                <li>[学会発表 2023/9/16-18] <span className='news-new-wrap'>NEW</span>日本昆虫学会第83回大会 @ 佐賀大学で口頭発表を行いました。</li>
                <li>[学会発表 2023/7/25] <span className='news-new-wrap'>NEW</span>10th Congress of International Society of Hymenopterists @ Romaniaで口頭発表を行いました。</li>
                <li>[論文掲載 2023/6/12] <span className='news-new-wrap'>NEW</span><i>European Journal of Taxonomy</i>に共著論文が掲載されました。</li>
                <li>[論文掲載 2023/5/26] <span className='news-new-wrap'>NEW</span><i>Zoological Studies</i>に筆頭論文が掲載されました。新種コウベアメバチを記載しました。</li>
                <li>[論文掲載 2023/4/19] <i>Biodiversity Data Journal</i>に筆頭論文が掲載されました。</li>
                <li>[資金獲得 2023/4/1] 3度目の挑戦でなんとか日本学術振興会 特別研究員（PD）に採用されました。</li>
            </ul>
        </>
    )
};
*/