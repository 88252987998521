import React from 'react'
import {Breadcrumb} from 'react-bootstrap';
import FigureEditingIcon from './FigureEditingIcon.png';
import {useTheme} from "../../../ThemeProvider";

export default function TipsFigureEditingMethods() {
  const { bgColor } = useTheme();
  return (
    <>
      <Breadcrumb style={{zIndex:"9",color:"white"}}>
            <Breadcrumb.Item href="/" style={{zIndex:"9"}}>Home</Breadcrumb.Item>
            <Breadcrumb.Item active style={{zIndex:"9",color:"white"}}>論文図版編集・準備のコツ</Breadcrumb.Item>
        </Breadcrumb>

        <h1 className='page-type-name'>TIPS</h1>

<div className='about-me-title-area-container'>
    <img className='about-me-img' src={ FigureEditingIcon } alt="So Shimizu" style={{backgroundColor:"white"}}/>
    <div className='about-me-title-area'>
        <h1 className='page-subtitle'>論文図版編集・準備のコツ</h1>
        <h1 className='page-title'>Technique for figure editing</h1>
    </div>
</div>

        <h2 className='section-title'>はじめに</h2>
        <p>
          論文は一度出版されると人類の文明が続く限り永遠に残り続け、科学的貢献のみならず執筆者の生きた証にもなります。
          私を含め多くの論文執筆者には、論文の図版にそれぞれのこだわりがあり、時間をかけて丁寧に図版を作成していると思います。
          しかし、雑誌や出版社により異なる紙面の幅や解像度に対応しきれず、気合を入れてカッコよく作った図版が紙面にフィットせず、
          ガッカリすることなどがあるかと思います。
          そこで、私自身の失敗を基に習得してきた、論文の図版を編集・準備するときのコツをメモしておきたいと思います。
        </p>

        <h2 className='section-title'>目指す図版</h2>
        <p>
          色々な研究者の図版を見ながら私が考えた目指すべき図版の条件は以下の通りです。
        </p>
        <ul>
          <li><span className='txt-underline'>紙面の文字印刷幅と図版の幅が一致している</span></li>
          <li><span className='txt-underline'>本文と図版の文字サイズの乖離を1.5倍程度以内に収める</span></li>
          <li><span className='txt-underline'>スケール等の線の幅が太くなり過ぎない</span></li>
        </ul>
        <p>
          上記のような条件を満たした図版を作成するため、ここでは以下の項目についてメモしていきます。
        </p>
        <ul>
          <li>図版のサイズ・解像度などの設定方法</li>
          <li>おまけ：電顕写真（SEM画像）に色を付ける方法</li>
        </ul>

        <h2 className='section-title'>図版のサイズ・解像度などの設定方法</h2>
        <p>
          図版のサイズ・解像度の調整方法が分からず調整方法をググると、
          フォトショの「イメージ → 画像解像度」で調整する方法程度しかおそらくヒットしません。
          しかし、以下の手順で作業を進めることで、
          投稿規約で求められる「線画は1,200 dpiで幅175 mm」などという条件で図版の作成が出来ます。
        </p>
        <ol>
          <li>
            <b>ステップ１：雑誌の紙面のサイズを調べる</b>
            <p>
              非常に当たり前ですが、重要な工程です。ターゲットジャーナルのカラムごとの幅、縦横の最大の長さを調べます。
            </p>
            <p>
              大抵、投稿規約に記述されていますので、<span className='txt-underline'>投稿規約はしっかりと読みましょう</span>。
              しかし、稀にジャーナル個々の投稿規約には図版等に関しての記述がなく、
              出版社などの大元のページを調べる必要があることもあります。
            </p>
            <p className="insert-column-container" style={{backgroundColor:bgColor}}>
              <b>Tips</b><br/>
              どうしても紙面サイズ・解像度の情報が見つからない場合は、
              ターゲットジャーナルから最近出版された論文のPDFをダウンロードし、
              Adobe Acrobat Pro DCの「ものさしツール」を用いてサイズを調べることが出来ます。
            </p>
          </li>
          <li>
            <b>ステップ２：図版のサイズ・解像度の調節</b>
            <p>
              写真やスキャンした素材をそのまま取り込み編集すると、実際の紙面との解像度とサイズのずれが生じ、
              紙面より幅が極端に狭い図版になったり、本文の文字サイズに対して図版の文字サイズが極端に大きくなったりしてしまい、
              残念な結果になる可能性が高いです。
              したがって、<span className='txt-underline'>図版は必ず事前にイラレやフォトショでサイズと解像度を設定したレイヤー上に貼り付けて編集する</span>ようにしましょう。
              具体的な手順は以下の通りです。
            </p>
            <ol>
              <li>「ファイル→新規」</li>
              <li>開いた新規ファイル作成ダイアログ画面上ででサイズと解像度を設定して作成</li>
              <li>貼り付けたい材料をコピーして貼り付け、または「レイヤーの複製」機能によりサイズを設定した新規ファイルに貼り付け</li>
            </ol>
            <p className="insert-column-container" style={{backgroundColor:bgColor}}>
              <b>Tips</b><br/>
              投稿規約に記載されている紙面の縦の最大サイズは、キャプションを考慮していないことが多いです。
              よって、投稿規約に記載の最大サイズピッタリに図版を作成すると、
              キャプションの量に応じて図の幅が狭くされたり、
              キャプションが他のページに書かれる可能性がありダサくなります。
              したがって、図版を作成する際はキャプションの量を考慮して高さを決めましょう。
            </p>
            <p className="insert-column-container" style={{backgroundColor:bgColor}}>
              <b>Tips</b><br/>
              一眼レフカメラの写真をイラレやフォトショで編集した図版だと容量が非常に大きく、
              投稿システムやメールでの送信容量の制限に引っかかることがあります。
              図版を組む前に個々の素材をフォトショで開きJPEGで圧縮後使用すると容量を減らすことが出来ます。
              また、図版を組み終えて書き出す際にJPEG圧縮にし、画質を下げるとかなり容量を小さくすることが出来ます。
            </p>
          </li>
        </ol>

        <h2 className='section-title'>おまけ：電顕写真（SEM画像）に色を付ける方法</h2>
        <p>
          よく論文で見る色付きの電顕写真をフォトショを使って作成する方法についてのメモです。
          フォトショで切り取りなどの編集経験があれば非常に簡単に行うことが出来ます。
        </p>
        <ol>
          <li>
            <b>ステップ１：電顕写真をフォトショで開く</b>
          </li>
          <li>
            <b>ステップ２：色を付けたい場所を選択</b><br/>
            「クイック選択ツール」を用いて大まかに選択後、
            「選択とマスク」機能を用いてより細かく選択を行います。
            色を付けたい部分がきれいに選択出来たら完了です。
          </li>
          <li>
            <b>ステップ３：適当な色で塗りつぶす</b><br/>
            「レイヤータブ」の下部にある「◑」マークから「べた塗り」を選択し塗りつぶします。
          </li>
          <li>
            <b>ステップ４：カバーモードを「通常」から「カラー」に変更</b><br/>
            これで完成です。色の濃淡は「透明度」を変更することで調整可能です。
          </li>
        </ol>

        <h2 className='section-title'>さいごに</h2>
        <p>
          図版のサイズや解像度の調整方法は、私の様に電子機器に疎い人間には案外難しいです。
          パソコンや画像編集に詳しい人、論文を書き慣れている人にとっては当たり前過ぎるためか、
          ググっても知りたい情報がほとんど出てこないことも多く、
          思い通りに図版を作成出来るようになったのはここ最近です。
          このメモが私と同様にパソコンが苦手な人、はじめて論文を書こうとしている人の助けに少しでもなると嬉しいです。
        </p>

        <br/>
        <br/>
        <p>
          最終更新日：2023/01/20
        </p>
    </>
  )
}